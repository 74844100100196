@import "../../../app/styles/variables";

.career__frame {
    display       : flex;
    flex-direction: column;
    margin        : 48px 0;
    padding       : 32px;
    border-radius : 16px;
    background    : $pc-gray-50;

    .frame-title {
        font-family  : NotoSemiBold;
        font-size    : 30px;
        line-height  : 38px;
        color        : $pc-gray-900;
        margin-bottom: 18px;

        &.anchor-offset {
            padding-top: 132px;
            margin-top : -132px;
        }
    }

    .frame-content {
        font-size  : 20px;
        line-height: 30px;
    }

    button {
        width     : 153px;
        margin-top: 24px;
    }
}

.vacancies__container {
    display       : flex;
    flex-direction: column;
    gap           :24px;

    .vacancies__item {
        border-bottom : 1px solid $pc-gray-100;
        padding-bottom: 24px;
    }

    .item-title {
        padding-left : 0;
        margin-bottom: 8px;
    }

    h5 {
        line-height: 24px;
    }

    .item-conditions {
        width     : 100%;
        margin-top: 24px;
        gap       :24px;

        .item-salary, .item-time {
            gap        : 8px;
            align-items: center;

            img {
                width : 20px;
                height: 20px;
            }

            h5 {
                font-family: NotoMedium;
                color      : $pc-gray-600;
                line-height: 24px;
            }
        }
    }
}