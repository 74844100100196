@import "../../../app/styles/variables";

.check__container {
    display            : flex;
    flex-wrap          : wrap;
    align-items        : baseline;
    height             : auto;
    position           : relative;
    cursor             : pointer;
    -webkit-user-select: none;
    -moz-user-select   : none;
    -ms-user-select    : none;
    user-select        : none;
    width              : auto;
    background-color   : transparent;
    
    input {
        position: absolute;
        opacity : 0;
        cursor  : pointer;
        height  : 0;
        width   : 0;
    }
    
    .checkmark {
        position        : absolute;
        top             : 0;
        left            : 0;
        
        background-color: transparent;
        border          : 1px solid $pc-gray-300;
        
    }
    
    &.checked {
        .checkmark {
        border-color: $pc-accent-600;
        }
    }
    
    input:checked ~ .checkmark {
        background-repeat  : no-repeat;
        background-position: center center;
    }

    input:active ~ .checkmark {
    box-shadow: 0px 0px 0px 4px #e7eeff;
    }
    
    .checkbox__item {
        color      : $pc-gray-700;
        font-family: NotoMedium;
    }

    /** SIZE **/
    &.sm {
        font-size    : 14px;
        border-radius: 4px;
        padding-left : 12px;

        .checkmark {
            height       : 16px;
            width        : 16px;
            border-radius: 4px;
        }

        .checkbox__item {
            font-size  : 14px;
            line-height: 20px;
        }
    }

    &.md {
        font-size    : 16px;
        border-radius: 6px;
        padding-left : 14px;

        .checkmark {
            height       : 20px;
            width        : 20px;
            border-radius: 6px;
        }

        .checkbox__item {
            font-size  : 16px;
            line-height: 24px;
        }
    }

    /** THEME **/
    &.checkbox {
        input:checked ~ .checkmark {
            background-image   : url("../../../../public/images/checkbox.png");
        }
    }

    &.radio {
        .checkmark {
            border-radius: 50%;
        }

        input:checked ~ .checkmark {
            background-image   : url("../../../../public/images/checkbox-dot.svg");
        }
    }
}