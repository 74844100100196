@import "../../../app/styles/variables";

.delete-project {
    &.notification-popup__wrapper {
        width    : 100%;
        max-width: 400px;
        padding  : 24px;
        top      : -30px;
        position : relative;

        .error {
            display: flex;
        }
    }

    .notification-popup__title {
        margin-top: 20px;
    }

    .notification-popup__subtitle {
        margin-bottom: 32px;
    }

    .delete-project-text-area {
        margin-bottom: 6px;
        textarea {
            width: 100%;
            border-radius: 8px;
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            height: 70px;
            resize: none;
            border: 1px solid $pc-gray-300;
            padding: 10px 14px;
            color: $pc-gray-500;
            font-size: 16px;
            line-height: 24px;
            outline: none;

            &.error {
                border-color: $pc-error-300;
            }

            &::-webkit-input-placeholder {
                color: $pc-gray-500;
                font-size: 16px;
                line-height: 24px;
            }

            &:-moz-placeholder {
                /* Firefox 18- */
                color: $pc-gray-500;
                font-size: 16px;
                line-height: 24px;
            }

            &::-moz-placeholder {
                /* Firefox 19+ */
                color: $pc-gray-500;
                font-size: 16px;
                line-height: 24px;
            }

            &:-ms-input-placeholder {
                color: $pc-gray-500;
                font-size: 16px;
                line-height: 24px;
            }
        }
    }

    .delete-project-actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        align-items: center;
        margin-top: 32px;
        gap: 12px;

        button {
            margin: 0 !important;
            width: 170px;
        }
    }

    .item-feature__container {
        display: flex;
        margin: 0!important;
    }
}

@media (max-height: 600px) {
    .delete-project {
        &.notification-popup__wrapper {
            top: auto;
        }
    }
}
