@import "../../../app/styles/variables";

/********************** RATES ************************/
.rates__container{
    padding: 96px 0px;

    .slick-slider {
        margin-bottom: 0;
    }

    .rates__title  {
        color         : $pc-gray-900;
        font-family   : NotoSemiBold;
        margin-bottom : 48px;
        line-height   : 44px;
    }

    .rates__right-container{
        min-width: 552px;
    }

    .rates__tabs {
        border-bottom: 1px solid $pc-gray-200;
        margin-bottom: 48px;
        width: 100%;
    }
    
    .rates__tabs-item {
        padding     : 0 5px 16px;
        cursor      : pointer;
        margin-bottom: -1px;

        h6 {
            font-family : NotoMedium;
            line-height : 20px;
        }

        &.active {
            color        : #426DCC;
            border-bottom: 2px solid #426DCC;
        }
    }

    .rates__tabs-item:first-child {
        margin-right: 15px;
    }

    .rate__description {
        max-width: 552px;
        width    : 100%;
        height   : 194px;
    }

    .rates__suptitle-tab {
        color        :$pc-accent-600;
        line-height  : 28px;
        display      : block;
        margin-bottom: 24px;
    }

    .rates__title-tab {
        line-height  : 30px;
        color        : $pc-gray-900;
        display      : block;
        margin-bottom: 24px;
        font-family  : NotoSemiBold;

        &.rates__title-margin {
            margin-bottom: 24px;
        }
    }

    .rates__description-tab {
        display      : block;
        min-width    : 552px;
        width        : 100%;
        line-height  : 24px;
        &.align {
            margin-bottom: 20px;
        }
    }

    .rates__slider-container{
        margin-left  : 96px;
        width        : 568px;
        min-height   : 510px;
    }

    .rates-slider__wrapper{
        .rates__slider-online{
            display        : flex !important;
            justify-content: space-between;
        }
    }

    .rates__content {
        padding: 0 32px;
    }

    .rates__badge {
        position: absolute;
        right   : 12px;
        top     : 12px;
    }

    .radio__container {
        h5 {
            color: $pc-gray-700;
            font-family: NotoMedium;
        }
    }

    .rates__wrapper {
        width : 568px;
        height: 228px;
        z-index: 9;

        img{
            width: 100%;
            height: 100%;
        }

       &:hover {
            .tooltipe-unanchor__container {
                display: block;
            }
        }

        .tooltipe-unanchor__container {
            right    : 12px;
            top      : 46px;     

            .title {
                line-height  : 20px;
                font-family  : NotoSemiBold;
                margin-bottom: 4px;
                color        : $pc-gray-700;
            }
    
            .sub__title {
                line-height: 20px;
            }
        }

        &.rates__wrapper-oneline {
            width : 256px;
            height: 480px;

            .tooltipe-unanchor__container {
                top      : 50%;
                left     : 50%;
                right    : auto;
                transform: translate(-50%, -50%);
            }
        }
    }

    .custom__dots {
        li {
            button {
                background: #D0D5DD;
            }
            &.slick-active {
                button{
                    background: #667085;
                }
            }
        }
    }

    .slick-dots{
        position: absolute;
        bottom: -41px;
    }

    .carousel-arrow {
        display: flex !important;
        position : absolute;
        align-items: center;
        justify-content: center;

        &::before {
            content: "";
        }
    }

    .tooltipe__container {
        &.right {
            margin-left: 9px;
            .rates-tooltip__title {
                line-height: 20px;
                font-family: NotoSemiBold;
                color      : $pc-gray-700;
                margin-bottom: 4px;
            }

            .rates-tooltip__subtitle {
                color      : $pc-gray-500;
                line-height: 20px;
            }
        }
    }
}

.hourly-rate__tabs{
    margin-right: 137px;
}

@media (max-width: 1360px) {
    .rates__container {
        padding: 80px 0px;

        .rates__title{
            font-size: 30px;
            line-height: 38px;
            margin-bottom: 36px;
        }


        .rates__right-container {
            min-width: 768px;
            padding: 0 24px;
            margin-bottom: 48px;
        }

        .rates__slider-container {
            margin-left: 0;
        }

        .rates__content {
            flex-direction: column;
        }

        .rates__tabs {
            margin-bottom: 36px;
        }

        .rates__tabs-item  {
            padding: 0 4px 16px;
            &:first-child {
                margin-right: 16px;
            }
        }

        .rate__description {
            max-width: 768px;
            height: auto;
        }

        .rates__title-tab {
            margin-bottom: 18px!important;
        }

        .rate__wrapper-content {
            margin-bottom: 24px;
        }
    }
}

@media (max-width: 1065px) {
    .rates__container {
        .rates__content {
            padding: 0;
        }
    }
}

@media (max-width: 890px) {
    .rates__container {
        .rates__right-container {
            min-width: 100%;
            width: 100%;
            padding: 0;
        }

        .rates__description-tab {
            min-width: 100%;
        }

        .rate__description {
            max-width: 100%;
        }
    }
}

@media (max-width: 640px) {
    .rates__container {
        .carousel-arrow {
            display: none !important;
        }
        .rates__wrapper-oneline{
            .rates__badge  {
                top: 6px;
                right: 6px;
            }
        }
    }
}

@media (max-width: 606px) {
    .rates__container {
        padding: 64px 0px;
        
        .rates-slider__wrapper{
            .rates__slider-online{
                justify-content: center;
                gap            : 16px;
            }
        }

        .rate__wrapper-content {
            margin-bottom: 16px;
        }

        .rates__title {
            margin-bottom: 24px;
        }

        .rates__tabs {
            margin-bottom: 24px;
        }
        
        .rates__title-tab {
            margin-bottom: 24px!important;
            font-size: 18px;
            line-height: 28px;
        }

        .rates__description-tab {
            &.align {
                margin-bottom: 16px;
            }
        }

        .radio__container {
            h5 {
                font-size: 14px;
                line-height: 20px;
            }
            .radio {
                width: 16px;
                height: 16px;
                margin-right: 8px;

                .radio__mark {
                    width: 6px;
                    height: 6px;
                }
            }
        }

        .rates__wrapper {
            height: 137px!important;
            width: 342px!important;
            margin: auto;
            margin-bottom: 16px;

            &:nth-child(2) {
                margin-bottom: 0;
            }
        }

        .rates__wrapper-oneline{
            height: 307px!important;
            width: 163px!important;
            margin: 0;
            margin-right: 16px;
        }

        .rates__slider-container {
            min-height: auto;
        }

        .tooltipe-unanchor__container {
            visibility: hidden;
        }

        .badge__container {
            &.rates__badge  {
                top: 6px;
                right: 6px;
            }

            .badge__text {
                font-size: 12px;
                line-height: 18px;
            }
        }
    }
}

@media (max-width: 365px) {
    .rates__container {
        .rates__wrapper {
            width: 93vw!important;
            height: auto!important;
        }

        .rates__wrapper-oneline{
            width: 45vw!important;

            .badge__container {
                &.rates__badge  {
                    top: -6px;
                    right: -6px;
                }
            }
        }
    }
}


