@import "../../../app/styles/variables";

.input__container {
    display       : flex;
    width         : 100%;
    flex-direction: column;

    &.disabled {
        background-color: $pc-gray-50 !important;
        pointer-events  : none;

        .input {
            color: $pc-gray-500 !important;
        }
    }

    .icon__right-icon, .icon__left-icon {
        position : absolute;
        top      : 50%;
        transform: translateY(-50%);
        height   : auto;
        width    : auto;
    }

    &.icon__right {
        .icon__right-icon {
            right       : 0;

            .icon-ava__img {
                margin-right: 14px;
            }
        }

        .input{
            padding-right: 38px;
        }
    }

    &.icon__left{
        .icon__left-icon {
            left: 15px;  
        }

        .input{
            padding-left: 42px;
        }
    }

    label {
        color        : $pc-gray-700;
        font-family  : NotoMedium;
        position     : absolute;
        top          : -23px;
        line-height  : 20px;
        font-size    : 14px;
    }

    .input__text-hint {
        line-height: 20px;
        color      : $pc-gray-500;
        position   : absolute;
        bottom     : -22px;
    }

    .input__line {
        display      : flex;
        flex-wrap    : nowrap;
        box-shadow   : 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        overflow     : hidden;

        .input__url {
            border-right : 1px solid $pc-gray-300;
            align-content: center;
            padding      : 10px 12px 10px 14px;
        }
    }

    .input {
        width           : 100%;
        box-sizing      : border-box;
        background-color: transparent;
        border          : 1px solid $pc-gray-300;
        border-radius   : 8px;
        color           : $pc-gray-900;
        padding         : 10px 14px;
        //box-shadow      : 0px 1px 2px rgba(16, 24, 40, 0.05);
        font-size       : 16px;
        line-height     : 24px;
        font-family     : NotoRegular;

        &:focus {
            border       : 1px solid $pc-accent-300;
            box-shadow   : 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px $pc-accent-100;
            border-radius: 8px;
        }
    }

    &.error {
        border-color: $pc-error-500!important;
        
        .input {
            &:focus {
                border-color: $pc-error-500!important;
                box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px $pc-error-100;
            }
        }

        .icon__left-icon {
            right: 0;
            height: 16px;

            .icon-ava__img  {
                margin-right: 14px;
            }
        }
    } 
}

.input__text-error {
    color: $pc-error-500;
    font-size: 14px;
    line-height: 20px;
    margin-top: 6px;
    display: inline-block;
}
