@import "../../../app/styles/variables";

.typing__container {
    display: flex;
    color      : $pc-gray-900;

    .editor {
        width      : 100%;
        max-height : 40px;
        padding    : 6px;
        overflow-y : auto;
        font-size  : 16px;
        line-height: 24px;
        color      : inherit;
        word-break : break-word;

        &::-webkit-scrollbar {
            display: none;
        }

        a {
            display: initial;
            color  : $pc-accent-700;

            &:hover {
                text-decoration      : underline;
                text-decoration-color: inherit;
                text-underline-offset: 4px;
            }
        }
    }

    &.error-color {
        * {
            color: inherit !important;
        }
    }
    [contenteditable][placeholder]:empty:before {
        content : attr(placeholder);
        position: absolute;
        color   : $pc-gray-400;
        background-color: transparent;
    }
}
