@import "../../app/styles/variables";

.cart__billing__wrapper {
    padding      : 32px;
    border-radius: 12px;
    border       : 1px solid #EAECF0;
    background   : #fff;
    box-shadow   : 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1);
    margin-bottom: 64px;

    &.margin_correction {
        margin-bottom: 32px;
    }
}

.checkout__cart__list {
    max-width: 655px;
    width: 655px;
}

.billing-information {
    color: $pc-gray-900;
    font-family: NotoSemiBold;
    line-height: 28px;
}

@media (max-width: 1280px) {
    .cart__billing__wrapper {
        margin-bottom: 0;
    }
}

@media (max-width: 768px) {
    .cart__billing__wrapper {
        padding: 32px 16px;
    }
}


