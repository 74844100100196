@import "../../../app/styles/variables";

.admin__info-menu {
    .admin-menu__item {
        cursor     : pointer;
        line-height: 20px;
        color      : $pc-gray-500;
        font-family: NotoSemiBold;
        background : transparent;
    }
}
