@import "../../../app/styles/variables";

.profile__bills {
    background-color: $pc-gray-50;
    margin-top      : 12px;
    padding         : 48px 32px 96px !important;
    align-self      : center;
    width           : 100%;

    .bills__header {
        width        : 100%;
        padding      : 0 !important;
        margin-bottom: 24px;
    }

    .admin__table {
        width: 100%;
        border-radius: 8px;
        border       : 1px solid $pc-gray-200;
        background   : $white;

        .admin__table-title {
            padding: 19px 24px;

            h4 {
                font-family: NotoMedium;
                line-height: 28px;
                color: $pc-gray-900;
            }
        }
    }

    .admin__table, .admin__table-header, .admin__table-row {
        width: 100%;
    }

    .admin__table-header {
        display   : flex;
        height    : 44px;
        justify-content: space-between;
        
        .bills-name, .bills-amount, 
        .bills-date, .bills-download {
            line-height: 20px;
            color      : $pc-gray-500;
            font-family: NotoMedium;
            display    : flex;
            align-items: center;
            padding    : 12px 24px;
        }

        .bills-name {
            min-width: 350px;
            width    : 600px;
        }

        .bills-amount {
            min-width: 135px;
        }
        
        .bills-date {
            min-width: 135px;
        } 

        .bills-download {
            min-width: 192px;
        }
    }

    .admin__table-body {
        height  : auto;
        overflow: visible;
    }

    .admin__table-row {
        justify-content: space-between;
        height: 80px;
        cursor: auto;

        &:hover {
            background-color: transparent !important;
        }
    }

    .bills-name {
        min-width: 350px;
        width    : 600px;
        padding  : 6px 24px;

        h5 {
            font-family       : NotoMedium;
            line-height       : 24px;
            color             : $pc-gray-700;
            overflow          : hidden;
            -webkit-line-clamp: 1;
            display           : -webkit-box;
            -webkit-box-orient: vertical;
        }

        h6 {
            line-height       : 20px;
            color             : $pc-gray-500;
            overflow          : hidden;
            -webkit-line-clamp: 1;
            display           : -webkit-box;
            -webkit-box-orient: vertical;
        }
    }

    .bills-amount {
        padding   : 16px 24px;
        min-width : 135px;
        overflow  : hidden;

        h6 {
            line-height  : 20px;
            color        : $pc-gray-500;
            font-family  : NotoBold;
        }
    }

    .bills-date {
        width    : 135px;
        min-width: 135px;
        padding  : 16px 24px;

        h5 {
            font-family: NotoMedium;
            line-height: 24px;
            color      : $pc-gray-500;
        }

        h6 {
            line-height  : 20px;
            color        : $pc-gray-500;
        }
    }

    .bills-download {
        min-width  : 192px;
        padding: 16px 24px;

        button {
            width          : 100%;
            justify-content: center;
        }
    }

    .pagination__container {
        margin  : 0;
        padding : 16px 0;
        position: relative;

        .antd-dropdown__container {
            position: absolute;
            width: 83px;
            right: 22px;

            .input__wrapper {
                top: -175px;
            }
        }
    }
}

@media (max-width: 900px) {
    .profile__bills {
        padding: 64px 32px !important;
        
        .admin__table-header {
            .bills-download {
                min-width: 84px;
            }
        }

        .bills-download {
            min-width: 84px;
    
            .link {
                padding: 7.5px 8px;

                img {
                    margin-right: 0;
                }
                span {
                    display: none;
                }
            }
        }
    }
}

@media (max-width: 796px) {
    .profile__bills {
        .admin__table-header {
            .bills-name {
                width: calc(100% - 354px);
                min-width: 209px;
            }
        }

        .bills-name {
            width: calc(100% - 354px);
            min-width: 209px;
        }
    }
}

@media (max-width: 768px) {
    .profile__bills {
        padding: 32px 0 !important;

        .bills__header {
            padding: 0 24px !important;

            .title {
                font-size: 24px !important;
                line-height: 32px !important;
            }
        }

        .admin__table {
            border: none;
            border-radius: 0;

            .admin__table-title {
                padding: 15px 24px;
            }
        }

    }
}

@media (max-width: 700px) {
    .profile__bills {
        .admin__table-header {
            .bills-name {
                width: calc(100% - 166px);
                min-width: 106px;
            }

            .bills-amount {
                display: none;
            }

            .bills-download {
                min-width: 60px;
            }
        }

        .admin__table-row {
            height: 60px;
        }

        .bills-name {
            width      : calc(100% - 166px);
            min-width  : 106px;
            align-items: flex-start;
            padding    : 8px 24px;

            h5 {
                font-family: NotoRegular;
                font-size  : 14px;
                line-height: 20px;
            }
        }

        .bills-date {
            min-width: 106px;
            padding: 8px 16px;

            h5 {
                font-family: NotoRegular;
                font-size  : 14px;
                line-height: 20px;
            }
        }

        .bills-amount {
            display: none;
        }

        .bills-download {
            min-width: 60px;
            padding  : 12px;
        }

        .pagination__container {
            .antd-dropdown__container {
                display: none;
            }
        }
    }
}
