@import "../../../../app/styles/variables";

.dashboard__container {
    display       : flex;
    flex-direction: column;
    width         : 100%;
    gap           : 16px;

    .dashboard__table-sales {
        border: 1px solid $pc-gray-200;
        border-radius: 12px;
        padding-top: 24px;
        width: 100%;

        .title {
            display: flex;
            justify-content: space-between;
            width: 100%;
            align-items: center;
            padding: 0 24px;
            margin-bottom: 20px;

            h4 {
                font-family: NotoBold;
                color      : $pc-gray-900;
            }

            h6 {
                font-family: NotoMedium;
                color      : $pc-gray-500;
            }
        }

        .admin__table {
            border-radius: 0;
            border: none;
            border-top: 1px solid $pc-gray-200;
            border-bottom: 1px solid $pc-gray-200;
        }
        .admin__table-body {
            height: 408px;
        }

        .dashboard__sales-action {
            display: flex;
            justify-content: center;
            padding: 16px 24px 24px;
        }
    }
}