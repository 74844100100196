@import '../../app/styles/variables';

.new-project__preview {
    width          : 100%;
    height         : 100%;
    border-radius  : 4px;
    background     : $pc-accent-50;
    display        : flex;
    align-items    : center;
    justify-content: center;

    h3 {
        line-height: 30px;
        color      : $pc-accent-600;
        font-family: NotoMedium;
        text-align : center;
        width      : 56px;
    }
}