@import "../../../app/styles/variables";

.avatar__wrapper {
    border-radius: 50%;
    background: $pc-accent-50;
    font-family: NotoMedium;
    
    .button {
        border-radius: 50%;
        &:hover {
            background: transparent;
        }
    }

    &.blue-light {
        background: $pc-accent-200;
    }
    
    &:active {
        -webkit-box-shadow: 0px 0px 0px 4px rgba(231, 238, 255, 1);
        -moz-box-shadow   : 0px 0px 0px 4px rgba(231, 238, 255, 1);
        box-shadow        : 0px 0px 0px 4px rgba(231, 238, 255, 1);
    }

    .avatar__img-wrapper {
        border-radius: inherit;
        overflow: hidden;
        width: 100%;
        height: 100%;
    }
    
    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
    
    .status__container {
        right: 0;
        bottom: 0;
    }

    .text__avatar {
        width       : 100%;
        height      : 100%;
        font-weight : 600;
        background  : transparent;
        pointer-events: none;
        cursor: auto;

        &.button {
            padding: 0;
        }

        span{
            font-family: NotoMedium;
        }

    }

    .status__container {
       position: absolute;
    }

    /** SIZE */
    &.xs {
        height: 24px;
        width : 24px;
        min-width: 24px;
        span{
            line-height: 18px;
        }
        .status__container {
            width: 8px;
            height: 8px;
        }
    }

    &.sm {
        height: 32px;
        width : 32px;
        min-width: 32px;
        span{
            line-height: 20px;
            font-size: 14px;
        }
        .status__container {
            width: 10px;
            height: 10px;
        }
    }

    &.md {
        height: 40px;
        width : 40px;
        min-width: 40px;
        span{
            font-size: 16px;
            line-height: 24px;
        }
        .status__container {
            width: 12px;
            height: 12px;
        }
    }

    &.lg {
        height: 48px;
        width : 48px;
        min-width: 48px;
        span{
            font-size: 16px;
            line-height: 18px;
        }
        .status__container {
            width: 14px;
            height: 14px;
        }
    }

    &.xl{
        height: 56px;
        width : 56px;
        min-width: 56px;
        span{
            font-size: 20px;
            line-height: 30px;
        }
        .status__container {
            width: 16px;
            height: 16px;
        }
    }

    &.xxl {
        height: 64px;
        width : 64px;
        min-width: 64px;
        span{
            font-size: 24px;
            line-height: 32px;
        }
        .status__container {
            width: 18px;
            height: 18px;
        }
    }

    &.profile {
        border-radius: 200px;
        border       : 4px solid #FFF;
        box-shadow   : 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);

        &.lg {
            min-width: 160px;
            width    : 160px;
            height   : 160px;
        }
    }
}