@import "../../../../app/styles/variables";

.free__container {
    align-self   : center;
    width        : 100%;

    .free__header {
        border       : 1px solid $pc-gray-200;
        border-radius: 12px;
        padding      : 24px;
        margin-bottom: 24px;

        .title {
            margin-bottom: 8px;
            font-family  : NotoBold;
            line-height  : 28px;
            color        : $pc-gray-900;
        }

        .subtitle {
            margin-bottom: 16px;
            line-height  : 24px;
            color        : $pc-gray-500;
        }

        &.pr {
            border   : none;
            max-width: 740px;
            padding  : 0;

            .title {
                margin-bottom: 24px;
                font-size    : 30px;
                line-height  : 38px;
            }

            .subtitle {
                max-width: 466px;
            }
        }

        .banner {
            position: absolute;
            top     : -20px;
            right   : -56px;
        }
    }

    .free__empty {
        display        : flex;
        flex-wrap      : wrap;
        justify-content: center;
        gap            : 24px;

        .free__header {
            max-width    : 510px;
            margin-bottom: 0;
        }

        .promote-empty__content {
            margin: 32px 0;

            .list-content {
                gap          : 12px;
                margin-bottom: 16px;
                align-items  : flex-start;

                img {
                    padding: 5px;
                }

                h5 {
                    line-height  : 24px;
                }
            }
        }

        .link {
            width: fit-content;
        }
    }

    .admin__table {
        width: 100%;
        border-radius: 8px;
        border       : 1px solid $pc-gray-200;
        background   : $white;
    }

    .admin__table-row {
        width: 100%;
    }

    .free__table-body {
        position  : relative;
        height    : calc(100vh - 501px);
        overflow  : auto;
        overflow-y: scroll;

        .loading-admin__container {
            z-index: 0;
        }
    }

    .admin__table-row {
        justify-content: space-between;
        height: 68px;
        cursor: auto;

        &:hover {
            background-color: transparent !important;
        }
    }

    .product-name {
        min-width: 235px;
        width    : 100%;
        padding  : 6px 24px;

        .check__container {
            padding     : 10px;
            margin-right: 12px;
        }

        .modal-image {
            .modal__children {
                margin-right: 12px;
                min-width: 56px;
                max-width: 56px;
                height: 56px;
                border-radius: 4px;
                border: 1px solid $pc-gray-200;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 56px;
                    object-fit: cover;
                }
            }
        }

        .product-title {
            overflow: hidden;
            max-height: 50px;
        }

        h5 {
            line-height  : 24px;
            color        : $pc-gray-700;
            overflow     : hidden;
            text-overflow: ellipsis;
            text-wrap    : nowrap;
        }

        h6 {
            line-height: 20px;
            color      : $pc-gray-500;
        }
    }

    .pagination__container {
        padding      : 16px 0;
        margin-bottom: 0;
    }
}