@import "../../../app/styles/variables";

.recovery-modal {
    display: flex;
    justify-content     : center;
    align-items         : center;
    position            : fixed;
    width               : 100%;
    top                 : 0;
    bottom              : 0;
    left                : 0;
    right               : 0;
    background          : rgba(52, 64, 84, 0.30);
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter     : blur(4px);
    z-index             : 99;
    overflow-x          : hidden;
    overflow-y          : auto;

    .recovery-modal__wrapper {
        position: absolute;
    }

    .recovery__form-container {
        box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
        margin-top: 16px;
    }

    .modal__close {
        position: absolute;
        right   : 32px;
        top     : 24px;
        cursor  : pointer;
    }
}

@media (max-height: 650px) {
    .recovery-modal {
        .recovery-modal__wrapper {
            top: 0;
        }
    }

}

@media (max-width: 605px) {
    .recovery-modal {
        .recovery__form-container {
            padding  : 24px 16px;
            width    : auto;
            max-width: 343px;

            .form__header {
                .form__header-title {
                    margin-top : 12px;
                    font-size  : 24px;
                    line-height: 32px;
                }

                .recovery__header-subtitle {
                    margin-top   : 8px;
                    margin-bottom: 16px;
                    font-size    : 14px;
                    line-height  : 20px;
                }
            }

            .form__content {
                .recovery__form {
                    .input__container {
                        margin-top: 36px;
                    }
                    .input__container:first-child {
                        margin-top: 24px;
                    }
                    .mt-24 {
                        margin-top: 0;
                    }
                    .button {
                        margin-top: 20px;
                        .lg {
                            line-height: 20px;
                            font-size  : 14px;
                            padding    : 10px 16px;
                        }
                    }
                }
                
            }
            .recovery__footer {
                margin-top: 16px;
            }
        }

    }
}
