@import "../../../app/styles/variables";

.licenses__container-description {
    padding: 96px 32px 64px;
}

.licenses-extended {
    margin-bottom   : 64px;
}


.licenses-exclusive, .licenses-extended {
    scroll-margin-top: 90px;
}

.extended__description,
.exclusive__description {
    max-width       : 384px;
    width           : 100%;
    margin-right    : 32px;

    .suptitle {
        color      : $pc-accent-700;
        line-height: 24px;
        font-family: NotoSemiBold;
    }

    .title {
        color      : $pc-gray-900;
        line-height: 44px;
        font-family: NotoSemiBold;
    }

    .description {
        line-height: 30px;
        color      : $pc-gray-500;
    }

}

.extended__content, .exclusive__content {
    .licenses__item {
        padding      : 24px;
        width        : 384px;
        background   : $pc-gray-50;
        margin-bottom: 32px;
        min-height   : 130px;

        &:nth-last-child(-n + 2) {
            margin-bottom: 0px;
        }

        &:nth-child(2n) {
            margin-left: 32px;
        }

        &.exclusive{
            background: $pc-success-50;  

            .licenses__item-title {
                color: $pc-success-900;
            }

            .licenses__item-description {
                color: $pc-success-700;
            }
        }

        .licenses__item-title {
            color      : $pc-gray-900;
            line-height: 30px;
            font-family: NotoSemiBold;
        }

        .licenses__item-description {
            color      : $pc-gray-700;
            line-height: 24px;
        }
    }
}

@media (max-width: 1340px) {
    .licenses__container-description {
        .licenses__item {
            width: 369px;
        }
    }
}

@media (max-width: 1296px) {
    .licenses__container-description {
        padding: 80px 24px;

        .licenses-extended, .licenses-exclusive {
            flex-direction: column;
            margin-bottom: 48px;
        }

        .licenses-exclusive {
            margin-bottom: 0;
        }

        .extended__description, .exclusive__description {
            max-width: 100%;
            text-align: center;
            margin-bottom: 24px;
        }

        .extended__content, .exclusive__content {
            width: 720px;
            margin: 0 auto;
        }

        .licenses__item {
            margin: 0;
            width: 348px;
            min-height: 230px;

            &:nth-child(2n) {
                margin-left: 24px;
            }

            &:nth-child(1) {
                margin-bottom: 24px;
            }

            &:nth-child(2) {
                margin-bottom: 24px;
            }

            &:last-child {
                margin-bottom: 0!important;
            }
        }

        .exclusive__content {
            .licenses__item {
                width: 100%;
                min-height: 130px;
                margin-bottom: 0;
            }
        }
    }
}

@media (max-width: 780px) {
    .licenses__container-description {
        .licenses__item{
            width: 100%;
        }

        .extended__content, .exclusive__content {
            width: 100%;
        }

        .licenses__item-title {
            font-size: 18px;
            line-height: 28px!important;
            margin-bottom: 4px;
        }

        .licenses__item {
            min-height: auto;
            margin-left: 0!important;
            margin-bottom: 24px!important;
        }

        .exclusive__content {
            .licenses__item {
                min-height: auto;
            }
        }
    }
}

@media (max-width: 606px) {
    .licenses__container-description {
        padding: 64px 24px;

        .extended__description, .exclusive__description {
            margin-bottom: 16px;
        }
    }  

    .extended__description, .exclusive__description {
        .title {
            margin-bottom: 16px;
        }
    }
}
