@import "../../app/styles/variables";

.background-default {
    &.preloader-waiting{
        margin-top: 80px;
    }
}

.waiting-popup__wrapper {
    padding      : 24px;
    border-radius: 12px;
    box-shadow   : 0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
    background   : $white;
    width        : 480px;
    position     : relative;
    z-index      : 9;

    .icon-ava__img {
        width   : 116px;
        height  : 116px;
        position: absolute;
        bottom  : 82%;
        left    : 57px;
    }

    .waiting-spinner {
        width : 55px;
        height: 56px;
        left  : 44%;
        top   : -112px;
    }

    .preloader__title {
        line-height: 28px;
        color      : $pc-gray-900;
        text-align : center;
    }
}

@media (max-width: 606px) {
    .background-default {
        &.preloader-waiting{
            margin-top: 56px;
        }
    }
}
