@import "../../../app/styles/variables";

.filters-checked__container {
    display    : flex;
    align-items: center;

    &.empty-filters {
        pointer-events: none;
    }

    img {
        margin-left: 4px;

        &.revers {
            transform: rotate(180deg);
        }
    }

    .button {
        padding        : 0;
        display        : flex;
        align-items    : center;
        justify-content: center;
    }

    .filter-checked__button {
        cursor: pointer;
    }

    .filter-checked__list-wrapper {
        display : none;
        position: relative;

        &.show {
            display: block;
        }
    }

    .filter-checked__list {
        top  : 30px;
        left : -80px;
        width: 229px;

        background   : #fff;
        border       : 1px solid #f2f4f7;
        border-radius: 8px;
        box-shadow   : 0 4px 6px -2px #10182808, 0 12px 16px -4px #10182814;
        max-height   : 450px;
        overflow-y   : scroll;
        padding      : 16px;
        position     : absolute;
        scrollbar-width: none;

        color: #101828;
        font-family: NotoMedium;
        line-height: 20px;
        z-index    : 3;

        .check__container {
            padding-left  : 32px;
            padding-bottom: 16px;
        }

        .checkmark {
            top: 2px;
            left: 1px;
        }
    }
}