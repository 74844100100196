@import "../../app/styles/variables";

.pagination__container {
    margin-bottom: 76px;
    gap: 167px;
    align-self: stretch;

    &.disabled {
        .input{
            pointer-events:none;
        }
    }

    &.top {
        .antd-dropdown__container .input__wrapper {
            top: -210px;
        }
    }

    .pagination__field {
        width: 76px;
        margin: 0 8px;

        input {
            text-align: center;
            appearance: none;
            -moz-appearance: textfield;
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
            }

            &:hover, &:focus {
                appearance: none;
                -moz-appearance: textfield;
            }
        }
    }

    .pagination__arrow-left {
        cursor: pointer;
        margin-right: 31px;

        .pagination__arrow {
            rotate: 180deg;
        }
    }

    .pagination__arrow-right {
        cursor: pointer;
        margin-left: 31px;
    }

    .antd-dropdown__container {
        max-width: 84px;
        min-width: 84px;
    }
}
