@import "../../../app/styles/variables";

.switcher__container {
  transition   : all .2s ease;
  cursor       : pointer;
  border-radius: 12px;

  &.disabled {
    pointer-events: none;
    background    : $pc-gray-100 !important;
    
    .switcher__bubble {
      background: $pc-gray-50 !important;
    }
  }

  .switcher__bubble {
    content      : '';
    position     : absolute;
    border-radius: 50%;
    background   : white;
    box-shadow   : 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
    transition   : all .2s cubic-bezier(.5,.1,.75,1.35);
    top          : 2px;
    left         : 2px;
  }

  /** SIZE */
  &.sm {
    width     : 36px;
    height    : 20px;
    min-width : 36px;

    .switcher__bubble {
      width : 16px;
      height: 16px;
    }
    &.checked {
      .switcher__bubble {
        transform: translatex(16px);
      }
    }
  }

  &.md {
    width     : 44px;
    height    : 24px;
    min-width : 44px;

    .switcher__bubble {
      width : 20px;
      height: 20px;
    }
    &.checked {
      .switcher__bubble {
        transform: translatex(20px);
      }
    }
  }
  
  /** THEME */
  &.dark {
    background: $pc-gray-100;

    &:hover {
      background: $pc-gray-200;
    }
    &:active {
      background: $pc-gray-50;
      box-shadow: 0px 0px 0px 4px $pc-accent-100;
    }
    &.checked {
      background: $pc-accent-600;

      &:hover {
        background: $pc-accent-600;
      }
      &:active {
        background: $pc-accent-600;
        box-shadow: 0px 0px 0px 4px $pc-accent-100;
      }
    }
  }

  &.light {
    background: $pc-accent-50;

    &:hover {
      background: $pc-accent-100;
    }
    &:active {
      background: $pc-accent-50;
      box-shadow: 0px 0px 0px 4px $pc-accent-100;
    }
    &.checked {
      background: $pc-accent-200;
      
      &:hover {
        background: $pc-accent-200;
      }
      &:active {
        background: $pc-accent-200;
        box-shadow: 0px 0px 0px 4px $pc-accent-100;
      }
    }
  }
}