@import "../../app/styles/variables";

.lang__menu {
    display    : flex;
    align-items: center;
    cursor     : pointer;

    .close, .open {
        display        : flex;
        font-family    : NotoBold;
        font-size      : 16px;
        line-height    : 24px;
        color          : $pc-accent-600;
        width          : 40px;
        height         : 40px;
        justify-content: center;
        align-items    : center;
    }

    .open {
        background-color: $pc-gray-50;
        border          : 4px solid $pc-accent-100;
        border-radius   : 8px;
    }
}

.menu-lang {
    border: 1px solid $pc-gray-100;
    border-radius: 8px ;
    box-shadow: 0 4px 6px -2px #10182808, 0 12px 16px -4px #10182814;
    overflow: hidden;
    width: auto;

    .menu__item {
        border-bottom: 1px solid $pc-gray-100;
    }

    .menu__item:first-child {        
        &:hover {
            background: $pc-gray-50;
        }
    }

    .menu__item:last-child {
        border-bottom: 0;
    }

    .link {
        color: $pc-gray-700;
        cursor: pointer;
        display: block;
        font-family: NotoMedium;
        font-size: 14px;
        line-height: 20px;
        padding: 10px 16px;
        text-align: left;
        text-decoration: none;
    }
}

@media (max-width: 606px) {
    .lang__menu {
        margin-right: 20px;
    }
}
