@import "../../../app/styles/variables";

.gallery__container-page {
    padding: 24px 0 92px;

    .gallery__list{
        opacity: 1;
        display   : flex;
        flex-wrap : wrap;
        transition: opacity 150ms ease 0.05s;
        margin    : 0 auto;
        padding   : 0 0px;
        gap       : 8px;
        

        &.gallery__visible {
            opacity: 0;
        }
    
        .product__item {
            background : $white;
            display    : flex;
            align-items: center;
            cursor     : pointer;
            position   : relative;
            float      : left;

            .hoverbackground__container {
                margin-right: 0;
            }

            .product__img {
                height        : auto;
                min-height    : 125px;
                width         : 100%;
                vertical-align: middle;
                margin        : auto;
            }
    
            .product__link {
                display: flex;
                width  : 100%;
                height : 100%;

                .badge__container {
                    position: absolute;
                    margin  : 16px;
                    z-index : 1;
                }
            }

            &.preloader {
                width: 600px;
                height: 250px;
                background-color: $pc-gray-50;

                .product__img, .product__link {
                    display: none;
                }
            }
        }        
    }

    .product__item--info {
        width           : 100%;
        margin          : 0 auto;
        padding         : 92px 0 32px;
        display         : flex;
        justify-content : center;
        scroll-margin-top: 280px;
    }

    .wrong_container {
        margin-bottom: 48px;
    }
}

@media (max-width: 606px) {
    .gallery__container-page {
        .gallery__list{
            gap: 8px;
        }
    }
}