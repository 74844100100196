@import "../../../app/styles/variables";

.header__bunner {
    background-image: url('../../../../public/images/main_banner.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding          : 96px 0;
    color            : $white;
    text-align       : center;
}

.banner__text {
    margin-bottom : 64px;
    line-height   : 44px;
    font-family   : NotoSemiBold;
}

.banner__info-item:nth-of-type(1) {
    padding-right : 150px;
    border-right  : 1px;
    border-color  : $pc-gray-200;
    border-style  : solid;
}

.banner__info-item:nth-of-type(2) {
    padding-left: 150px;
}

.banner__info-item {
    h3 {
        font-size     : 60px;
        line-height   : 72px;
        margin-bottom : 12px;
        font-family   : NotoSemiBold;
    }

    h4 {
        line-height : 28px;
        font-family : NotoMedium;
    }
}

@media (max-width: 1065px) {
    .header__bunner {
        padding: 80px 0;
    }

    .banner__text{
        font-size    : 30px;
        line-height  : 38px;
        margin-bottom: 48px;
    }

    .banner__info-item {
        max-width: 484px;
        width    : 100%;
        
        &:nth-of-type(1) {
            padding-right: 32px;
        }

        &:nth-of-type(2) {
            padding-left: 32px;
        }

        h3 {
            font-size  : 48px;
            line-height: 60px;
        }

        h4 {
            font-size  : 18px;
            line-height: 28px;
        }
    }
}

@media (max-width: 606px) {
    .header__bunner {
        background-position: calc(100% - 24%);
        padding            : 64px 0;
    }

    .banner__text{
        font-size  : 24px;
        line-height: 32px;
    }

    .banner__info-item {

        h3 {
            font-size  : 36px;
            line-height: 44px;
        }

        h4 {
            font-size  : 18px;
            line-height: 28px;
        }
    }
}