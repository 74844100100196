@import "../../../app/styles/variables";

.free-product-popup__bkg {
    overflow: auto;
    height  : 100vh;
    z-index : 99;

    /*.free-popup__illustration-card {
        max-width   : 50%;
        max-height  : 472px;
        width       : auto;
        height      : auto;
        position    : relative;
        margin      : 12px;
        margin-right: -4px;

        .badge__container {
            position: absolute;
            margin  : 16px;
            z-index : 1;
        }

        .product__img {
            width : 100%;
            height: 100%;
            max-height: inherit;
        }

        .hoverbackground__container {
            .cart__button {
                display: none;
            }
        }
    }*/
}

.free-popup__wrapper {
    padding      : 8px 16px 32px 16px;
    border-radius: 12px;
    box-shadow   : 0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
    background   : $white;
    width        : 100%;
    max-width    : 360px;
    z-index      : 9;
    margin       : 16px;

    .background-default.preloader-waiting {
        margin-top: 0;
    }

    .free-popup__actions {
        display: flex;
        justify-content: flex-end;

        .free-popup__close {
            padding: 8px 0;
            cursor : pointer;
        }
    }

    .message__wrapper {
        margin-bottom: 24px;

        .message__container {
            width: 100%;
            border: none;
            box-shadow: none;
            background-color: $pc-gray-50;

            .message-text__content {
                color: $pc-gray-500;
            }
            .message__addition {
                display: none;
            }
        }
    }

    .textarea__container {
        .textarea {
            background-color: $pc-gray-50;
        }

        textarea::-webkit-input-placeholder {
            color: $pc-gray-400;
        }
        
        textarea:-moz-placeholder { /* Firefox 18- */
            color: $pc-gray-400;
        }
        
        textarea::-moz-placeholder {  /* Firefox 19+ */
            color: $pc-gray-400;
        }
        
        textarea:-ms-input-placeholder {
            color: $pc-gray-400; 
        }
    }

    .free-product-popup__purchases {
        display    : flex;
        align-items: center;
        margin-top : 20px;

        img {
            width       : 16px;
            height      : 14px;
            margin      : 6px;
            margin-right: 10px;
        }

        h5 {
            line-height: 24px;
        }
    }

    .free-product-popup__actions {
        display        : flex;
        justify-content: center;
        margin-top     : 24px;

        button {
            width  : 100%;
            display: flex;
            justify-content: center;

            .button__container-icon {
                margin-right: 8px;
            }
        }
    }

    .free-popup__info {
        display    : flex;
        align-items: flex-start;
        margin-top : 12px;
        gap        : 12px;

        .free-popup__info-icon {
            margin-top: 4px;
        }

        h5 {
            line-height: 24px;
            color: $pc-gray-400;
        }
    }

    .product__container-info {
        .close__icon {
            display: none;
        }
        .product__info-container {
            display: none;
        }
        .info__description {
            .additional-data-mobile {
                display: none !important;
            }
            .info__descr, 
            .editillustration__wrapper,
            .license_line,
            .info__compatible,
            .info__filestype {
                margin-bottom: 18px;
            }
            .price_line {
                margin: 0;
            }
            .info__btn {
                display: none;
            }
        }
    }

    &.apply {
        .free-popup__actions {
            .free-popup__close {
                padding-bottom: 0;
            }
        }

        .free-product-popup__actions {
            margin-top: 12px;
        }
    }
}

@media (max-width: 1065px) {
    .free-product-popup__bkg {
        /*.free-popup__illustration-card {
            display: none;
        }*/

        .free-popup__wrapper {
            .product__container-info {
                width: auto;
            }

            .info__description {
                display: block;
                max-width: 348px;
            }

            .info__description-tablet {
                display: none;
            }
        }
    }
}
