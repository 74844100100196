@import "../../../../app/styles/variables";

.products__empty {
    display        : flex;
    flex-wrap      : wrap;
    justify-content: center;
    gap            : 24px;

    .products__header {
        max-width    : 510px;
        margin-bottom: 30px;

        .title {
            color: $pc-accent-700;
            font-family: NotoBold;
            line-height: 24px;
        }
        .subtitle {
            color: $pc-gray-900;
            font-family: NotoBold;
            font-size: 30px;
            line-height: 38px;
        }
    }

    .promote-empty__content {
        margin: 32px 0;

        .list-content {
            gap          : 12px;
            margin-bottom: 16px;
            align-items  : flex-start;

            img {
                padding: 5px;
            }

            h5 {
                line-height  : 24px;
            }
        }
    }

    .link {
        width: fit-content;
    }

    .tooltipe__container {
        top: 36px;
        
        .tooltip__ancor {
            display: none;
        }
    }
}