@import "../../app/styles/variables";

.footer {
    background : $pc-gray-700;
    padding    : 64px 0 0;
    margin-top : auto;
    opacity    : 0;

    animation-fill-mode: forwards;
    animation-delay    : 0.5s;
    animation-name     : showon;

    &.footer-cart-margin {
        margin-top: 64px;
        z-index   : 98;
    }
}

.footer__container {
    margin-bottom  : 64px;
    justify-content: space-between;
}

.footer__main {
    flex-direction: column;
    max-width     : 452px;
}

.footer__main-banner {
    display        : flex;
    position       : relative;
    border-bottom  : 1px solid $pc-gray-600;
    margin-bottom  : 4px;

    img {
        height: 150px;
    }
}

.footer__main-desc {
    color      : $pc-gray-200;
    line-height: 20px;
    h5 {
        font-family  : NotoBold;
        line-height  : 24px;
        margin-bottom: 4px;
    }
}

.footer__menu {
    width          : 100%;
    justify-content: space-between;
    min-width      : 438px;
    max-width      : 684px;
    padding-left   : 24px;

    .footer__menu-item {
        li {
            margin-top: 16px;
        }

        li:not(:nth-child(2)){
            margin-top: 12px;
        }
    }
}

@media (max-width: 1065px) {
    .footer {
        padding: 48px 0 0;
    }

    .footer__container {
        margin-bottom: 48px;
    }
}

@media (max-width: 768px) {
    .footer__container {
        flex-direction: column;
        align-items   : center;
    }
    .footer__main {
        margin-right : 0;
        margin-bottom: 24px;
        min-width    : 282px;
        max-width    : none;
    }
    .footer__main-banner {
        width          : 100%;
        justify-content: center;
    }

    .footer__menu {
        min-width   : 343px;
        max-width   : none;
        padding-left: 0;
    }
}

@media (max-width: 606px) {
    .footer__menu {
        flex-wrap: wrap;
        gap      : 32px;
    }
    .footer__menu-item {
        margin-right: 32px;

        &:last-child {
            margin-right: 0;
        }
    }
}

@media (max-width: 470px) {
    .footer__menu {
        flex-wrap: wrap;
        gap      : 32px;
    }
}

@media (max-width: 370px) {
    .footer__main {
        width    : 100%;
        min-width: auto;
    }
    .footer__menu {
        min-width: auto;
    }
}

@keyframes showon {
    to {
         opacity: 1;
     }
 }
