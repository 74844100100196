@import "../../app/styles/variables";

.greetings-message {
    padding      : 32px;
    background   : $white;
    box-shadow   : 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);

    .greetings-message__conteiner {
        display        : flex;
        flex-direction : column;
        justify-content: center;
        align-items    : center;
        gap            : 32px;
    }

    .greetings-message__content {
        display       : flex;
        flex-direction: column;
        align-items   : flex-start;

        .sub-title {
            color        : $pc-accent-700;
            font-family  : NotoBold;
            font-size    : 16px;
            line-height  : 24px;
            margin-bottom: 12px;
        }
        .title {
            color        : $pc-gray-900;
            font-family  : NotoBold;
            font-size    : 30px;
            line-height  : 38px;
            margin-bottom: 32px;
        }
        .check-items {
            display       : flex;
            padding-left  : 16px;
            flex-direction: column;
            align-items   : flex-start;
            gap           : 16px;
            align-self    : stretch;

            .check-item {
                display    : flex;
                align-items: self-start;

                img {
                    margin: 5px;
                }

                h5 {
                    line-height: 24px;
                    margin-left: 12px;
                }
            }
        }
    }

    .greetings-message__image {
        max-width: 468px;

        img {
            width: 100%;
        }

        .greetings__banner-mobile {
            display  : none;
            max-width: 257px;
        }
    }
}

@media (max-width: 671px) {
    .greetings-message {
        padding: 24px;
        box-shadow: none;

        .greetings-message__conteiner {
            gap: 12px;
        }

        .title {
            font-size    : 24px;
            line-height  : 32px;
        }

        .greetings-message__content {
                .check-items {
                    gap: 12px;
                
                .check-item {
                    h5 {
                        font-size  : 14px;
                        line-height: 20px;
                    }
                }
            }
        }

        .greetings-message__image {
            .greetings__banner {
                display: none;
            }

            .greetings__banner-mobile {
                display: block;
            }
        }
    }
}