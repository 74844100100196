@import "../../../app/styles/variables";

.filters-select__container {
    display    : flex;
    align-items: center;
    position: relative;

    .button {
        width          : 40px;
        height         : 40px;
        padding        : 0;
        display        : flex;
        align-items    : center;
        justify-content: center;

        .button__container-icon-left {
            margin-right: 0;
        }
    }

    .filters-select__indicator {
        display: none;
        position: absolute;
        top: -5px;
        right: -5px;
        width: 18px;
        height: 18px;
        background-color: $pc-success-500;
        border-radius: 50%;
        border: solid 2px $white;

        &.view {
            display: block;
        }
    }
}

.filters-select__popover {
    overflow     : auto;
    max-height   : calc(100dvh - 135px);
    border       : 1px solid $pc-gray-100;
    border-radius: 8px;
    box-shadow   : 0 4px 6px -2px #10182808, 0 12px 16px -4px #10182814;

    .ant-popover-inner {
        padding: 12px;
    }

    .filter-select__wrapper {
        display       : flex;
        flex-direction: column;
        gap           : 12px;

        .filter-select__list {
            border-radius: 8px;
            border       : 1px solid $pc-gray-100;
            

            .list-title {
                border-bottom: 1px solid $pc-gray-100;
                padding      : 12px 16px;
                font-family  : NotoMedium;
                line-height  : 20px;
            }

            .check__container {
                margin-top : 10px;
                margin-left: 16px;
                padding    : 0px 16px 10px 32px;
                line-height: 20px;
                font-size  : 14px;
            }

            .list-actions {
                display: flex;
                margin: 4px 16px 12px;

                .button {
                    padding: 0;
                }
            }
        }
    }
}

@media (max-width: 670px) {
    .ant-popover {
        max-height: calc(100dvh - 131px);
    }
}

@media (max-width: 606px) {
    .ant-popover {
        max-height: calc(100dvh - 107px);
    }
}