@import "../../../../app/styles/variables";

.delete-product-background {
    z-index: 9999999;
}

.delete-product-popup__wrapper {
    display       : flex;
    border-radius : 12px;
    background    : $white;
    padding       : 24px;
    margin        : 16px;
    z-index       : 9;
    gap           : 20px;
    flex-direction: column;
    align-items   : center;

    .delete-product-popup__content {
        text-align: center;
    }

    .delete-product-popup__title {
        line-height  : 28px;
        font-family  : NotoSemiBold;
        color        : $pc-gray-900;
        margin-bottom: 8px;
    }

    .delete-product-popup__subtitle {
        line-height: 20px;
        color      : $pc-gray-500;
    }

    .delete-product-popu__actions {
        display: flex;
        margin-top: 32px;
        gap: 12px;

        button {
            width          : 206px;
            display        : flex;
            justify-content: center;
        }
    }
}

@media (max-width: 606px) {
    .delete-product-popup__wrapper {
        .delete-product-popu__actions {
            flex-direction: column;
            align-items   : center;

            .button {
                width: 100%;
            }
        }
    }
}