@import '../../../app/styles/variables';

.admin__content {
    width         : 100%;
    height        : 100%;
    display       : flex;
    flex-direction: column;
    position      : relative;
    overflow      : hidden;

    .admin__content-name {
      padding      : 21px 16px;
      border-bottom: 1px solid $pc-gray-200;
    }

    .admin__content-screen {
     flex-grow: 1;
    }

    .admin__chat-input {
      height     : 76px;
      border-top : 1px solid $pc-gray-200;
      background: #eee;
    }
  }