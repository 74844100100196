@import "../../../app/styles/variables";

.signup__terms {
    .checkbox__item {
        padding-left: 28px;
        
        .link {
            font-family: NotoRegular;
        }
    }
}
.signup__footer {
    display    : flex;
    align-items: center;
    margin-top : 32px;

    span {
        font-size: 14px;
    }

    .button {
        font-family: NotoRegular;
    }
}
