@import "../../../app/styles/variables";

/********************** GALLERY ************************/
.gallery__container {
    padding: 243px 0 96px;

    .gallary__arows {
        .carousel-arrow {
            &.slick-prev, &.slick-next {
                position: static;
                transform: none;
            }
            &::before {
                content: "";
            }
        }
    }

}

.gallery__content {
    text-align: center;

    h1 {
        line-height  : 44px;
        color        : $pc-gray-900;
        font-family  : NotoSemiBold;
        margin-bottom: 24px;
    }

    h3 {
        line-height  : 30px;
        margin-bottom: 64px;
        display      : inline-block;

        .gallery__count {
            color        : $pc-accent-700;
            margin-left  : 4px;
            margin-right : 4px;
            margin-bottom: 0;
        }
    }
}

.slick-slider {
    margin-bottom: 6px;
}

.slide__item {
    margin-right: 8px;
    cursor      : pointer;
    position    : relative;

    .badge__container {
        position: absolute;
        margin  : 16px;
        z-index : 1;
    }

    &:hover {
        .actions__container {
            display: block;
        }
    }

    .hoverbackground__container {
        margin-right: 8px;
    }

    &.preloader {
        width: 400px !important;
        //height: auto;
        min-height: 140px;
        max-height: 212px;
        background-color: $pc-gray-50;

        .icon-ava__img {
            opacity: 0;
        }
    }
}

.actions__container {
    bottom    : 0;
    position  : absolute;
    right     : 8px;
    height    : 60px;
    left      : 0;
    padding   : 10px 12px;
    background: rgba(6, 5, 38, 0.43);
    display   : none;
}

@media (max-width: 1065px) {
    .gallery__container {
        padding: 200px 0 80px;

        .slick-slide {
            .slide__item {
                & > img {
                    height: 210px;
                }
            }
        }
    }

    .gallery__content {
        max-width: 550px;

        h1 {
            font-size    : 30px;
            line-height  : 38px;
            margin-bottom: 20px;
        }
        h3 {
            font-size    : 18px;
            line-height  : 28px;
            margin-bottom: 48px;
        }
    }

    .gallary__arows {
        margin-top: 18px;
    }
}

@media (max-width: 606px) {
    .gallery__container {
        padding: 154px 0 18px;

        .slick-slider {
            margin-bottom: 3px;
        }

        .slick-slide {
            .slide__item {
                margin-right: 4px;

                & > img {
                    height: 140px;
                }
            }
        }
        .slick-track {
            height: 140px;
        }
    }

    .gallery__content {
        max-width: 100%;

        h1 {
            margin-bottom: 16px;
        }
    }

    .hoverbackground__container {
        visibility: hidden;
    }

    .gallary__arows {
        margin-top: 16px;

        .carousel-arrow {
            width : 36px;
            height: 36px;

            svg {
                width : 7px !important;
                height: 16px !important;
            }

            &.next {
                margin-left: 20px;
            }
        }
    }
}

@media (max-width: 456px) {
    .gallery__container {
        .slick-list {
            overflow: hidden !important;
            height  : 140px;
        }
    }
}

@media (max-width: 375px) {
    .gallery__container {
        padding-top: 71px;
    }
}
