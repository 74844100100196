@import "../../../app/styles/variables";
.reset__form {
    padding: 96px 32px 48px 32px;

    .login__header-subtitle {
        text-align: center;
    }

    .form__header-logo-container {
        height: 72px;
        width : 72px;

        .form__header-icon {
            position        : relative;
            right           : 3px;
            top             : -3px;

            display         : flex;
            width           : 78px;
            height          : 78px;
            padding         : 18px;
            justify-content : center;
            align-items     : center;
            border-radius   : 50%;
            border          : 12px solid $pc-accent-50;
            background      : $pc-accent-100;
        }
    }

    .form__content {
        &.reseted {
            button.primary {
                display        : flex;
                justify-content: center;
                width          : 100%;
            }
        }
    }

    .form__footer {
        margin-top: 32px;
    }

    .link-gray {
        img {
            opacity: .7;
        }

        &:hover {
            img {
                opacity: .9;
            }
        }

    }
}
