@import "../../../app/styles/variables";

.modal-image {
    cursor: pointer;

    .modal-image__background {
        display        : none;
        background     : $pc-gray-700;
        position       : fixed;
        top            : 0;
        bottom         : 0;
        left           : 0;
        right          : 0;
        margin         : 0;
        z-index        : 99;
        padding        : 64px;
        overflow       : hidden;
        -webkit-overflow-scrolling: touch;
        cursor         : pointer;

        &.open {
            display: flex;
        }

        &.scale {
            padding: 0;
        }

        .modal-image__image {
            display        : flex;
            justify-content: center;
            width          : 100%;
            height         : 100%;
            overflow       : hidden;

            img {
                transform-origin: 0 0;
                cursor: zoom-in;
                cursor: -moz-zoom-in; 
                cursor: -webkit-zoom-in;
            }

            &.scale {
                overflow: auto;
                white-space: nowrap;
                justify-content: space-evenly;
                padding        : 16px;

                img {
                    height: fit-content;
                    cursor: zoom-out;
                    cursor: -moz-zoom-out;
                    cursor: -webkit-zoom-out;
                }
            }
        }

        .modal__top-panel {
            display        : flex;
            padding        : 32px 32px 24px 32px;
            justify-content: flex-end;
            align-items    : flex-start;
            position       : absolute;
            top            : 0;
            left           : 0;
            width          : 100%;
            height         : 80px;
            background     : linear-gradient($pc-gray-700 2.82%, rgba(52, 64, 84, 0.00) 97.19%);
        }

        .modal__close {
            cursor: pointer;

            .icon-ava__img {
                width : 24px;
                height: 24px;
            }
        }
    }
}

@media (max-width: 768px) {
    .modal-image {
        .modal-image__background {
            padding: 0;
        }
    }
}