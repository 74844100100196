@import "../../../../app/styles/variables";

.edit-time-popup__wrapper {
    border-radius: 12px;
    background   : $white;
    padding      : 20px 16px;
    width        : 343px;
    z-index      : 9;
    margin       : 16px;

    .edit-time-popup__title {
        line-height  : 28px;
        font-family  : NotoSemiBold;
        color        : $pc-gray-900;
        margin-bottom: 8px;
    }

    .edit-time-popup__subtitle {
        line-height  : 20px;
        color        : $pc-gray-500;
        margin-bottom: 16px;
    }

    .edit-time-popup__content {
        max-height     : 327px;
        margin-top     : 16px;
        margin-bottom  : 23px;
        overflow-y     : auto;
        overflow-x     : hidden;

        .edit-time__item {
            width: 311px;
            padding: 15px;
            border-radius: 8px;
            border: 1px solid $pc-gray-200;
            background: $pc-gray-25;

            &.delete {
                border: 1px solid $pc-gray-100;
                background: $white;

                .content_milestone {
                    .hours_milestone{
                        color: $pc-gray-400;
                    }
        
                    .time_milestone {
                        color: $pc-gray-300;
                    }
                }

                .input__container {
                    background: $pc-gray-50;
                }
            }
        }

        .edit-time__info {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

        }

        .edit-time__time {
            margin-right: 4px;
        }

        .content_milestone {
            .hours_milestone{
                color        : $pc-gray-900;
                font-family  : NotoMedium;
                line-height  : 20px;
            }

            .time_milestone {
                line-height  : 20px;
            }
        }

        .edit-time__actions {
            display: flex;

            .button {
                padding: 8px;

                .mr-15 {
                    margin: 0;
                }
            }
        }

        .edit-time__hours-minutes {
            display        : flex;
            justify-content: space-between;
            gap            : 16px;
            margin-top     : 8px;
            padding-top    : 29px;
            border-top     : 1px solid $pc-gray-200;
    
            .input__container {
                width: 147px;
                background-color: $white;
            }
        }
    }

    .edit-time__buttons {
        display: flex;
        gap    : 12px;

        .edit-time__btn {
            width          : 100%;
            justify-content: center;
        }
    }
}