@import "../../../app/styles/variables";

.mobile-admin-wrapper {
    display: flex;
    width  : 100%;

    .users__panel {
        max-width   : none;
        width       : 100%;
        border-right: none;
        overflow    : hidden;
        position    : unset;

        .admin__table-header {
            .user-name {
                width: 45%;
                min-width: 165px;
                max-width: none;
            }

            .user-email {
                width: 55%;
                min-width: 210px;
                max-width: none;
            }
        }

        .admin__table-body {
            height : calc(100dvh - 309px);
            overflow-y: auto;

            .user-name {
                width: 45%;
                min-width: 165px;
                max-width: none;
            }

            .user-email {
                width: 55%;
                min-width: 210px;
                max-width: none;
            }
        }
    }

    .panel__user-info, .admin-user-info__buttons-block {
        display: none;
    }

    &.content-open {
        height : 100dvh;

        .users__panel {
            display: none;
        }
    
        .panel__user-info {
            display: flex;
            flex-direction: column;
            
            .user-info__header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 8px 16px;

                .user-info__back-block {
                    .button {
                        padding: 0;
                        .button__container-icon {
                            margin-right: 0;
                            width: 32px;
                            height: 32px;
                        }
                    }
                }

                .admin-user-info__buttons-block {
                    display: flex;
                    position: static;
                    padding: 0;
                }
            }

            .admin-user-info__wrapper {
                width: 100%;
                max-width: none;
                height: calc(100vh - 60px);
                padding-bottom: 0;
            }
        }
    }

    .background-default {
        top: 41px;

        &.delete-modal {
            padding: 16px;
            top    : 0;
        }
    }
}

.mobile-user-panel {
    margin: 34px 16px 64px;

    .mobile-user-panel__header {
        margin-bottom: 48px;
        .title {
            font-family  : NotoBold;
            font-size    : 30px;
            color        : $pc-gray-900;
            line-height  : 38px;
            margin-bottom: 16px;
        }
        .sub-title {
            line-height: 28px;
        }
    }

    .mobile-user-panel__banner {
        display        : flex;
        justify-content: center;

        img {
            width: 278px;
        }
    }
}

@media (max-width: 768px) {
    .users-wrapper {    
        .users__panel {
            max-width: calc(100% - 300px);
        }
    }
}

@media (max-width: 606px) {
    .mobile-admin-wrapper {
        .users__panel {
            .admin__table-body {
                height : calc(100dvh - 285px);
            }
        }
    }
}
