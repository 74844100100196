@import "../../app/styles/variables";

.not-found-message {
    background     : $white;
    display        : flex;
    flex-direction : column;
    position       : absolute;
    justify-content: center;
    align-items    : center;
    padding        : 88px 32px;
    border-right   : 1px solid $pc-gray-200;
    border-bottom  : 1px solid $pc-gray-200;
    bottom         : 0;
    top            : 0;
    left           : 0;
    right          : 0;
    gap: 16px;

    .not-found__content {
        text-align: center;
        width     : 100%;
        max-width : 352px;
    }

    .not-found__title {
        line-height  : 28px;
        font-family  : NotoSemiBold;
        color        : $pc-gray-900;
        margin-bottom: 4px;
    }

    .not-found__subtitle {
        line-height: 20px;
        color      : $pc-gray-500;
    }
}

@media (max-width: 671px) {
    .not-found-message {
        position: static;
        border  : none;
    }
}