@import "../../../app/styles/variables";

/********************** HOW WE WORK ************************/
.how_we_work__container {
 background : $pc-gray-100;
}

.how_we_work__content {
    height: 738px;
}

.how_we_work__left {
 width        : 494px;
 margin-right : 96px;
 color        : $pc-gray-900;

 a{
    padding: 0!important;
 }

 h1 {
     line-height : 44px;
     font-family : NotoSemiBold;
 }

 h3 {
     display     : inline-block;
     line-height : 30px;
     color       : $pc-gray-500;
 }
}

.how_we_work__right{
    min-width: 598px;
    height   : 100%;
    margin-top: 56px;

   .how_we_work__banner {
        width   : 100%;
        position: absolute;
        left    : 0;
        bottom  : -69px;
   }
}

@media (max-width: 1200px) {
    .how_we_work__content {
        flex-direction: column;
        padding-top   : 80px;
        height        : 785px;
    }

    .how_we_work__left {
        max-width   : 720px;
        width       : 100%;
        margin-right: 0;

        h1 {
            font-size  : 30px;
            line-height: 38px;
        }

        h3 {
            font-size    : 18px;
            line-height  : 28px;
            margin-bottom: 20px;
        }
    }

    .how_we_work__right {
        min-width: 480px;

        .how_we_work__banner {
            bottom: -77px;
        }
    }
}

@media (max-width: 650px) {
    .how_we_work__content {
        padding-top: 64px;
        height     : 682px;
    }

    .how_we_work__left{
        h1 {
            margin-bottom: 16px;
        }

        h3 {
            margin-bottom: 32px;
        }
    }

    .how_we_work__right {
        min-width: 344px;

        .how_we_work__banner {
            bottom: -56px;
        }
    }
}

@media (max-width: 455px) {
    .how_we_work__content {
        height: 745px;
    }
}

@media (max-width: 360px) {
    .how_we_work__right {
        min-width: 244px;

        .how_we_work__banner {
            bottom: -39px;
        }
    }
}

@media (max-width: 260px) {
    .how_we_work__right {
        min-width: 150px;

        .how_we_work__banner {
            bottom: -24px;
        }
    }
}