@import "../../../app/styles/variables";

/********************** Rights to product ************************/

.rights__main-container {
    padding    : 96px 24px;
    background : $pc-gray-50;
}

.rights__container {
    color      : $pc-gray-900;
    padding    : 50px;
    box-shadow : 0px 24px 48px -12px rgba(16, 24, 40, 0.18);
    background : $white;
    column-gap : 64px;
}

.rights__description {
    max-width: 356px;
    width    : 100%;

    .subtitle {
        font-family: NotoSemiBold;
        color      : $pc-accent-700;
        line-height: 24px;
    }

    .title {
        font-family: NotoSemiBold;
        color      : $pc-gray-900;
        line-height: 44px;
    }
}

.rights__content {
    column-gap: 64px;
}

.rights__item {
    display       : flex;
    align-items   : flex-start;
    gap           : 16px;
    max-width     : 346px;
    min-height    : 86px;
    margin-bottom : 32px;

    .icon {
        margin-top: 2px;
    }

    img {
        width : 24px;
        height: 24px;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.rights__item-content {
    gap    : 8px;
    display: grid;

    .title {
        font-family: NotoSemiBold;
        line-height: 30px;
    }
    
    .description {
        color      : $pc-gray-500;
        line-height: 24px;
    }
}

@media (max-width: 1350px) {
    .rights__main-container {
        .rights__description {
            max-width: 300px;
        }
    }
}

@media (max-width: 1285px) {
    .rights__container {
        max-width     : 720px;
        flex-direction: column;
        padding: 36px;
    }

    .rights__main-container {
        padding: 80px 24px;

        .rights__description {
            max-width: 100%;
        }

        .title {
            font-size    : 30px;
            line-height  : 38px;
            margin-bottom: 48px;
        }

        .rights__content {
            column-gap: 48px;
        }

        .rights__item {
            margin-bottom: 24px;
            max-width    : 300px;
            gap          : 16px;
            
            &:last-child {
                margin-bottom: 0;
            }

            img {
                width : 24px;
                height: 24px;
            }

            .rights__item-content {
                gap: 0;
                h3 {
                    font-size    : 20px;
                    line-height  : 30px;
                    margin-bottom: 8px;
                }
            }
        }
    }
}

@media (max-width: 750px) {
    .rights__container {
        .rights__item {
            max-width : 100%;
            min-height: auto;
            margin-bottom: 16px;
        }
    }
}

@media (max-width: 606px) {
    .rights__container {
        padding: 24px;

        .rights__content {
            flex-wrap     : nowrap;
            flex-direction: column;
        }

        div.rights__item-content {
            gap: 0;

            .title {
                font-size    : 18px;
                line-height  : 28px;
                margin-bottom: 4px;
            }
        }
    }
}
