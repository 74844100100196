@import "../../../app/styles/variables";

.total__wrapper {
  display: flex;
  line-height: 24px;
  flex: 1;
  margin-right: 30px;
  flex-wrap: wrap;

  &.result {
    max-width: 100%;
    flex: 1;
  }

  span {
    color: $pc-accent-700;
    margin-bottom: 0;
    display: inline;
  }
}

@media (max-width: 475px) {
  .total__wrapper {
    h5 {
      font-size: 14px;
      line-height: 20px;
    }
  }
}
