@import "../../../app/styles/variables";

/********************** HOW IT WORKS ************************/

.how_it_works__container {
    padding: 96px 0 96px;
}

.how_it_works__item {
    min-height: 546px;
    max-width: 300px;
    width: 100%;
    margin-right: 26px;
    padding: 24px;
    border: 1px solid $pc-gray-200;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);

    a {
        display: block;
        margin-top: 32px;
        padding: 10px 20px!important;
    }
}

.how_it_works__preview {
    margin: 0 auto 24px;
    width: 260px;
    height: 220px;

    img {
        width: 100%;
        height: 100%;
    }
}

.how_it_works__suptitle {
    background-color: $pc-gray-100;
    border-radius: 16px;
    padding: 4px 12px;
    margin-bottom: 16px;
    font-family: NotoMedium;
    font-size: 14px;
    color: $pc-gray-700;
    line-height: 20px;
    display: inline-block;
}

.how_it_works__title {
    margin-bottom: 4px;
    line-height: 30px;
    font-weight: 600;
    color: $pc-gray-900;
    font-family: NotoSemiBold;
}

.how_it_works__description {
    line-height: 24px;
    display: block;
}

.how_it_works--no-ilustator__container {
    margin-left: 70px;

    h1 {
        line-height: 44px;
        margin-bottom: 20px;
        color: $pc-gray-900;
        font-family: NotoSemiBold;
        width: 300px;
    }

    h3 {
        line-height: 30px;
    }
}

@media (max-width: 1065px) {
    .how_it_works__container {
        padding: 80px 0 80px;
        flex-direction: column;
    }

    .how_it_works--no-ilustator__container {
        order: 1;
        margin-left: 0;
        margin-bottom: 48px;
        align-items: center;

        h1 {
            width: 100%;
            text-align: center;
            font-size: 30px;
            line-height: 38px;
        }

        h3 {
            text-align: center;
            max-width: 534px;
            width: 100%;
            margin: auto;
            font-size: 18px;
            line-height: 28px;
        }
    }

    .how_it_works__wrapper {
        order: 2;
        justify-content: center;
        flex-wrap: wrap;
    }

    .how_it_works__item {
        padding: 20px;
        min-height: 530px;

        &:nth-child(2) {
            margin-right: 0;
        }

        a {
            margin-top: 24px;
        }
    }
}

@media (max-width: 690px) {
    .how_it_works__wrapper {
        flex-direction: column;
        align-items: center;
    }

    .how_it_works__item {
        margin-right: 0;
        padding: 16px;
        min-height: 510px;

        &:nth-child(1) {
            margin-bottom: 20px;
        }
    }
}

@media (max-width: 606px) {
    .how_it_works__container {
        padding: 64px 0 64px;
    }

    .how_it_works--no-ilustator__container {
        h1 {
            margin-bottom: 16px;
        }

        h3 {
            margin: 0;
        }
    }

    .how_it_works__item {
        a {
            margin-top: 22px;
        }
    }
    
    .how_it_works__title {
        font-size: 18px;
        line-height: 28px;
    }

    .how_it_works__suptitle {
        font-size: 16px;
        line-height: 24px;
    }
}
