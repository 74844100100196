@import "../../../app/styles/variables";

.dropdown-uploader__container {
    display: flex;
    width: 100%;

    .uploader-wrapper {
        border-radius: 8px;
        border       : 1px solid $pc-gray-200;
        background   : $white;

        padding       : 16px 24px;
        flex-direction: column;
        align-items   : center;
        align-self    : stretch;
    }

    &.progress-error {
        .uploader-wrapper {
            border-color: $pc-error-500;
        }

        .marked {
            color: $pc-error-500!important;
        }
    }

    .xxl {
        margin-right: 20px;
        min-width   : 64px;
    }

    .user-project__img-wrapper {
        width        : 100%;
        height       : auto;
        display      : block;
        overflow     : visible;
        border-radius: 0;
        border       : none;
        background   : none;
    }

   
    .item-feature__container {
        margin-bottom: 12px;
    }

    .dropdown-uploader__text {
        margin-bottom: 4px;
        width: 100%;
        text-align: center;

        h6{
            line-height : 20px;
            font-size   : 14px;
            display: inline;
        }

        .marked {
            font-family : NotoBold;
            color       : $pc-accent-700;
            margin-right: 4px;
        }
    }

    .uploader-text {
       font-size: 12px;
       line-height: 18px;
       text-align: center;
    }

}