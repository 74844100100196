@import "../../../app/styles/variables";

.user__info {
  position  : -webkit-sticky;
  position  : sticky;
  top       : 0px;
  background: $white;
  z-index   : 9;

  .user__info-menu {
    height         : 57px;
    justify-content: space-between;
    border-bottom  : 1px solid $pc-gray-200;
    gap            : 16px;
    padding        : 23px 32px 0 32px;
  }

  .user__info-content {
    border-left: 1px solid $pc-gray-200;
  }

  .user-menu__item {
    line-height: 20px;
    font-family: NotoMedium;
    width      : 72px;
    cursor     : pointer;
    text-align: center;

    &.active {
      color        : $pc-accent-700;
      border-bottom: 2px solid $pc-accent-700;
    }

    &.disabled {
      pointer-events: none;
      cursor: default;
      color: $pc-gray-300;
    }
  }

  
}
