@import "../../../app/styles/variables";

/********************** Licenses provide ************************/

.license_provide {
    padding      : 12px 32px;
    margin-bottom: 64px;
}

.license_provide__content {
    align-items     : flex-start;

    .license_provide__description {
        margin-left: 16px;
        h3 {
            line-height: 30px;
            font-family: NotoMedium;
            margin-bottom: 8px;
            color: $pc-gray-900;
        }

        h4{
            line-height: 28px;
            display: inline;
        }

        a {
            display: inline;
            font-family: NotoRegular;
            font-size: 18px;
            line-height: 28px;
        }
    }
}

@media (max-width: 1065px) {
    .license_provide {
        margin-bottom: 48px;
    }

    .license_provide__content {
        .license_provide__description {
            a {
                line-height: 28px;
            }
        } 
    }
}

@media (max-width: 780px) {
    .license_provide {
        padding: 12px 24px;
    }
}

@media (max-width: 475px) {
    .license_provide__content {
        .item-feature__container {
            min-width: 48px;
            min-height: 48px;
        }
    }
    .license_provide {
        padding: 12px 16px;
    }
} 
