@import "../../../app/styles/variables";

/********************** TRUST ************************/
.trust__container {
 padding: 0 32px 96px;
}

.trust__content {
 background    : #F5F8FF;
 padding       : 64px 32px;
 border-radius : 8px;
 align-items   : baseline;

 h1 {
     line-height   : 44px;
     margin-bottom : 20px;
     color         : $pc-gray-900;
     font-family   : NotoSemiBold;
 }

 h3 {
     line-height   : 30px;
 }
}

.trust__wrapper {
 max-width : 768px;
 width     : 100%;
}

@media (max-width: 1280px) {
    .trust__content {
        flex-direction: column;
    }

    .trust__wrapper {
        max-width: 100%;

        h1 {
            font-size: 30px;
            line-height: 38px;
            margin-bottom: 20px;
        }

        h3 {
            font-size: 18px;
            line-height: 28px;
            margin-bottom: 20px;
        }
    }
}

@media (max-width: 1065px) {
    .trust__container {
        padding: 80px 24px;
    }
    .trust__content {
        padding: 48px 24px;
        
        .link.xxl {
            line-height: 24px;
            font-size  : 16px;
            padding    : 12px 20px;
            min-width  : auto;
         }
    }
}

@media (max-width: 606px) {
    .trust__container {
        padding: 64px 16px;
    }
    .trust__content {
        padding: 48px 20px;
    }

    .trust__wrapper {
        h1 {
            margin-bottom: 16px;
        }
        h3 {
            margin-bottom: 32px;
        }
    }
}