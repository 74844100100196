@import "../../../app/styles/variables";

.search__container_withbg {
    background   : $pc-gray-100;
    border       : none;
    border-radius: 8px;
    flex-grow    : 1;
    position     : relative;

    .input__container {
        border: none;

        input {
            padding-right: 42px;
            border-color: $pc-gray-100;
        }
    }

    .button {
        display: none;
        position: absolute;
        padding: 0;
        right: 15px;
        bottom: 10px;
        pointer-events: all;

        .mr-15 {
            margin-right: 0;
        }

        .button__img-left {
            width: 20px;
            height: 20px;
            margin: 2px
        }
    }

    &:focus-within, &:focus, &:hover {
        .button {
            display: flex;

            &:hover {
                background-color: $pc-gray-50;
                border-radius: 50%;
            }
        }
    }
}