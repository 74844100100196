@import '../../app/styles/variables';

.no-result__container{
    text-align: center;

    .no-result__title {
        line-height: 28px;
        font-family: NotoSemiBold;
        color: $pc-gray-900;
    }
    
    .no-result__text {
        line-height: 24px;
        color      : $pc-gray-500;
    }

    .no-result-ico{
        .item-feature__container {
            img {
                width: 18px;
                height: 18px;
            }
        }
    }
}
