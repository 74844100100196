@import "../../app/styles/variables";

.avatar-label__container {
   .avatar__wrapper  {
      margin-right: 12px;
   }

   .author__name {
      display: flex;
      flex-wrap: wrap;
   }

   h6 {
      font-family: NotoSemiBold;
      line-height: 20px;
   }

   .button:active{
      box-shadow: none!important;
      background-color: transparent!important;
   }

   .avatar__wrapper:active{
      box-shadow: none!important;
   }
}
