@import "../../../app/styles/variables";

.job__form-container {
    display       : flex;
    max-width     : 655px;
    padding       : 32px;
    flex-direction: column;
    border-radius : 12px;
    border        : 1px solid $pc-gray-200;
    background-color: $white;

    .typing__container {
        [contenteditable][placeholder]:empty:before {
            position: inherit;
        }
    }

    .form__header-title {
        color        : $pc-gray-900;
        font-family  : NotoBold;
        font-size    : 18px;
        line-height  : 28px;
        margin-bottom: 46px;
    }

    .job__name-block {
        display: flex;
        width  : 100%;
        gap    : 24px;

        .job__input {
            width: 50%;
            scroll-margin-top: 80px;
        }
    }

    .job__input-block {
        .input__container label {
            text-wrap: nowrap;
        }

        .description {
            font-size  : 14px;
            line-height: 20px;

            a {
                display        : inline;
                padding        : 0;
                text-decoration: underline;
            }
        }

        .label {
            color      : #344054;
            font-family: NotoMedium;
            line-height: 20px;
            font-size  : 14px;
        }

        .job__typing-form {
            margin       : 0;
            margin-top   : 2px;
            min-height   : 128px;
            padding      : 10px 14px;
            border-radius: 8px;
            border       : 1px solid $pc-gray-300;
            background   : $white;
        }

        .tooltip__children {
            width: auto;

            .tooltipe__container {
                max-width  : 432px;
                white-space: inherit;
                bottom     : 30px;
                right      : 2px;

                .tolltipe__subtitle {
                    color      : #667085;
                    font-size  : 14px;
                    line-height: 20px;
                }

                ul {
                    margin-left: 18px;
                }

                li {
                    list-style: disc;
                }
            }

            .help__icon {
                position: absolute;
                right   : 14px;
                top     : -31px;
            }
        }

        &.pr-32 {
            input {
                padding-right: 32px;
            }
        }
    }

    .job__vacancies-block {
        .job__vacancies-title {
            color      : $pc-gray-700;
            font-family: NotoMedium;
            font-size  : 14px;
            line-height: 20px;
        }

        .fd-column {
            margin-top    : 12px;
            flex-direction: row;
            align-items   : flex-start;
            flex-wrap     : wrap;
            gap           : 16px;
        }

        .radio__container .radio {
            min-width: 20px;
        }
    }

    .job__footer {
        display         : flex;
        align-items     : center;
        margin-top      : 32px;
        padding         : 16px;
        background-color: $pc-gray-50;
        border-radius   : 12px;

        span {
            font-size: 14px;
        }
    }
}

@media (max-width: 768px) {
    .job__form-container {
        max-width: none;
    }
}

@media (max-width: 557px) {
    .job__form-container {
        padding: 32px 16px;

        .job__name-block {
            flex-direction: column;
            gap           : 43px;

            .job__input {
                width: 100%;
            }
        }

        .job__form {
            .button {
                width          : 100%;
                justify-content: center;
            }
        }
    }
}
