@import '../../../app/styles/variables';

.accordion__container {
    width        : 100%;
    border-bottom: 1px solid $pc-gray-200;

    &:last-child {
        border: none;
    }

    .accordion__container-item {
        
        cursor : pointer;

        h4 {
            color      : $pc-gray-900;
            font-family: NotoMedium;
        }

        h5 {
            color: $pc-gray-500
        }

        .accordion__ico {
            position: absolute;
            right   : 0;
            top     : 28px;
        }

        /* SIZE */
        &.md {
            padding: 24px 52px 32px 0;

            .title {
                line-height: 28px;
                
            }

            .description {
                line-height: 24px;
            }
        }

        &.sm {
            padding: 24px 28px 24px 0;

            .title {
                font-size  : 14px;
                line-height: 20px;
            }

            .description {
                font-size  : 14px;
                line-height: 20px;
            }
        }
    }
}
