@import "../../../../app/styles/variables";

.rdrDefinedRangesWrapper {
    display: none;
}

.rdrDateDisplayWrapper {
    background: none;

    .rdrDateInput {
        border-radius: 8px;
        border: 1px solid $pc-gray-300;
        box-shadow: none;
        padding: 8px 14px;

        input {
            font-size: 16px;
            line-height: 24px;
            color: $pc-gray-900;
            height: 100%;
            text-align: left;
        }
    }
}

.rdrMonthAndYearPickers {
    font-size: 16px;
    line-height: 24px;
    color: $pc-gray-900;
}

.rdrStartEdge,
.rdrEndEdge {
    & ~ .rdrDayNumber {
        span {
            color: $pc-gray-700 !important;
        }
    }
}

.date-range__wrapper {
    margin : 16px;
    z-index: 9;
}

.date-range__wrapper-item {
    padding: 20px 24px;
    background: $white;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;

    .rdrMonth {
        padding: 0;
    }

    .rdrCalendarWrapper {
        display: flex;

        .rdrMonthAndYearWrapper {
            order: 1;
            height: 40px;
            margin-bottom: 12px;

            .rdrNextPrevButton {
                position: relative;
                background: transparent;

                i {
                    display: none;
                }

                &::before {
                    content: "";
                    background-image: url("../../../../shared/ui-kit/icons/chevron-gray500.svg");
                    width: 13px;
                    height: 15px;
                    display: block;
                    background-repeat: no-repeat;
                    background-size: contain;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }

                &.rdrPprevButton {
                    &::before {
                        transform: translate(-50%, -50%) rotate(180deg);
                    }
                }
            }
        }

        .rdrDateDisplayWrapper {
            order: 2;
            height: 40px;
            margin: 0;
            margin-bottom: 22px;

            .rdrDateDisplay {
                height: 100%;
                margin: 0;
                justify-content: center;
            }
        }

        .rdrMonths {
            order: 3;
        }

        .rdrWeekDay {
            font-family: NotoMedium;
            line-height: 20px;
            font-size: 14px;
            color: $pc-gray-700;
            margin-bottom: 14px;
        }
    }
}

.date-range__wrapper-active {
    padding: 16px 24px;
    background: $white;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid $pc-gray-200;

    .date-range__btn {
        width: 134px;
        justify-content: center;
    }
}
