@import "../../app/styles/variables";

.cart__summary {
    width        : 465px;
    height       : auto;
    padding      : 32px;
    border-radius: 12px;
    border       : 1px solid $pc-gray-200;
    background   : $white;
    box-shadow   : 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);

    .message__wrapper {
        margin-bottom: 24px;

        .smile {
            width : 16px;
            height: 16px;
        }
    }

    .avatar__wrapper {
        width : 56px;
        height: 56px; 
    }

    .message__container {
        max-width  : 333px;
        background : $pc-accent-50;
        color      : $pc-gray-500;
        font-size  : 16px;
        line-height: 24px;
        box-shadow : none;
        border     : none;

        .bot-cart__subtitle {
            line-height: 24px;
            color      : $pc-gray-500;

            .donate-btn {
                display: inline;
                padding: 0;
            }
        }

        .message__addition {
            display: none;
        }

        .message__text-owner {
            margin: 0;
        }
    }

    .vat_notincluded {
        color      : $pc-gray-400;
        line-height: 32px;
        margin-left: 8px;
    }

    .cart-security {
        h5 {
            color      : $pc-gray-400;
            line-height: 24px;
        }
    }
}

.cart-summary__item {
    display        : flex;
    align-items    : center;
    justify-content: space-between;


    &.cursor-hover {
        cursor: pointer;
    }

    &.cart-summary-saves {
        padding: 6px 0;
    }
   
    &.p-t-b {
        padding: 16px 0;
    }

    &.p-b {
        padding-bottom: 8px;
    }

    &.p-t {
        padding-top: 8px;
    }

    &:first-child{
        padding: 0;
    }

    .cart-summary__title {
        line-height: 30px;
        color      : $pc-gray-900;
        font-family: NotoSemiBold;
    }

    .cart-summary__title-desc, .cart-summary__subtitle-desc {
        line-height: 24px;
        color      : $pc-gray-900;
        font-family: NotoMedium;
    }

    .cart-summary__title-desc{
        color: $pc-gray-500;
    }

    .cart-summary__subtitle {
        line-height: 24px;
        color      : $pc-gray-900;

        .gray-text {
            color: $pc-gray-500;
        }
    }

    .cart-summary__total, .cart-summary__total-desc {
        color       : $pc-gray-900;
        font-family : NotoSemiBold;
        line-height : 32px;
        font-size   : 24px;
    }

    .cart-benefit__subtitle-desc {
        color: $pc-gray-500;

        &.bold {
            font-family: NotoMedium;
        }

        &.error {
            color      : $pc-error-700;
            font-family: NotoMedium;
            font-size  : 16px;
            line-height: 24px;
        }
    }

    .tooltip__children {
        margin-top: 3px;
        margin-left: 8px;

        .tooltipe__title {
            line-height  : 20px;
            color        : $pc-gray-700;
            margin-bottom: 4px;
            font-family: NotoSemiBold;
        }

        .tooltipe__subtitle {
            line-height: 20px; 
            color: $pc-gray-500;
        }

        .tooltipe__container {
            right: -8px;
        }
    }

    .arrow__icon {
        transform  : rotate(270deg);
        width      : 20px;
        height     : 20px;
        margin-left: 8px;

        &.down {
            transform: rotate(90deg);
        }
    }
}

.benefit__link {
    font-style : 14px;
    line-height: 20px;
}

.pay__link {
    width          : 100%;
    justify-content: center;
    margin         : 34px 0 12px;
}

.cart-summary-lock {
    margin-right: 4px;
}

@media (max-width: 1280px) {
    .cart__summary {
        margin-top: 80px;
        width: 100%;

        .message__container  {
             max-width: 100%;
        }

        .cart-summary__item {
            .cart-summary__total {
                display: flex;
                align-items: center;
            }
        }
        .cart-summary__item {
            .tooltip__children {
                display: none;
            }
        }
    }
}

@media (max-width: 768px) {
  .cart__summary {
    margin-top: 64px;
    padding   : 16px;
  }
}

@media (max-width: 390px) {
    .cart__summary {    
        .avatar__wrapper {
            width : 48px;
            height: 48px;

            .status__container {
                width: 10px;
                height: 10px;
            }
        }
        
    }

    .cart-summary__total_descr {
      display: block;
    }
  }