@import "../../../app/styles/variables";

.bring__container {
    background : $white;
    padding-bottom: 80px; // email-subscribe

    .slick-slider {
        margin-bottom: 0px;
    }

    .bring__banner {
        display        : flex !important;
        justify-content: center;
        
        .bring__background {
            width: 100%;
        }
        img {
            position: absolute;

            &.banner-mobile {
                display: none;
            }
        }
    }

    .slick-slide {
        .bring__background {
            height    : 206px;
            margin-top: 194px;

            &.background__fill-1 {
                background: linear-gradient(180deg, #3290D1 0%, #3290D1 35.56%, #37A0CF 64.3%, #3AAACE 100%);
            }

            &.background__fill-2 {
                background: linear-gradient(180deg, #3290D1 0%, #3290D1 35.56%, #37A0CF 64.3%, #3AAACE 100%);
            }

            &.background__fill-3 {
                background: linear-gradient(180deg, #3290D1 0%, #3290D1 35.56%, #37A0CF 64.3%, #3AAACE 100%);
            }
        }
    }
}

@media (max-width: 1250px) {
    .bring__container {
        .bring__banner {
            img {
                height: 330px
            }
            .bring__background {
                height: 135px !important;
            }
        }
    }
}

@media (max-width: 1065px) {
    .bring__container {
        .bring__banner {
            img {
                height: 222px
            }
            .bring__background {
                height    : 114px !important;
                margin-top: 108px !important;
            }
        }

        .d-flex.jc-center.mt-24 {
            margin-top: 20px;
        }
    }
}

@media (max-width: 696px) {
    .bring__container {
        .bring__banner {
            img {
                width: 910px;
                height: 200px;
            }
            
            .bring__background {
                height    : 103px !important;
                margin-top: 97px !important;
            }
        }

        .d-flex.jc-center.mt-24 {
            margin-top: 16px;
        }
        .carousel-arrow.xl {
            width : 36px;
            height: 36px;

            &.next {
                margin-left: 20px;
            }
        }
    }
}

@media (max-width: 606px) {
    .bring__container {
        padding-bottom: 64px; // email-subscribe

        .bring__banner {
            .bring__background {
                height    : 95px !important;
                margin-top: 105px !important;
            }

            img {
                display: none;
                width  : auto;

                &.banner-mobile {
                    display: block;
                }
            }
        }
    }
}

@media (max-width: 450px) {
    .bring__container {
        .bring__banner {
            position        : relative;
            right           : -82px;
            justify-content : end;

        }
    }
}
