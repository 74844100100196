@import "../../app/styles/variables";

.cart__container {
    align-items  : flex-start;
    padding-top  : 64px;
    margin-bottom: 64px;

    .cart__shopping-list {
        min-width: 665px;
        padding  : 20px 0;

        .check__container {
            margin      : auto;
            margin-right: 16px;
            margin-left : 0;
            font-size   : 16px;
            line-height : 24px;
            padding-left: 24px;
        }
    }

    .cart__select-remove {
        padding      : 16px 0;
        border-bottom: 1px solid $pc-gray-200;
        margin-bottom: 36px;

        .button {
            margin-left: 2px;
        }
    }

    .cart-resume__wrapper {
        position: sticky;
        top     : 90px;
    }
}

.cart__title {
    color      : $pc-gray-900;
    line-height: 44px;
    font-family: NotoSemiBold;
    margin     : 64px auto 0;
}


@media (max-width: 1280px) {
    .cart__title {
        margin-top : 48px;
        font-size  : 30px;
        line-height: 38px;
        max-width  : 720px;
    }

    .cart__container {
        flex-direction: column;
        max-width     : 720px;
        margin        : 0 auto;
        padding-top   : 48px;
        padding-bottom: 48px;

        .cart__shopping-list {
            min-width: 100%;
        }        
    }

    .footer {
        &.footer-cart-margin {
            margin-top: 48px;
        }
    }
}

@media (max-width: 980px) {
    .cart__container {
        max-width: 100%;
    }
}

@media (max-width: 768px) {
    .cart__title {
        margin-top: 24px;
        padding   : 0 32px;
    }

    .cart__container {
        max-width  : 100%;
        padding-top: 0;

        .cart__shopping-list {
            padding: 48px 16px 0;
        }

        .cart__item {
            padding: 16px 0;
        }
    }
}

@media (max-width: 620px) {
    .cart__title {
        padding: 0 16px;
    }
    
    .cart__container
    .cart__shopping-list {
        padding: 48px 0 0;
    }
}
