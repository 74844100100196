@import "../../app/styles/variables";

.cart__summary-project {
    width        : 465px;
    height       : auto;
    padding      : 32px;
    border-radius: 12px;
    border       : 1px solid $pc-gray-200;
    background   : $white;
    box-shadow   : 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);


    .cart-security {
        h5 {
            color      : $pc-gray-400;
            line-height: 24px;
        }
    }

    .cart-summary__item {
        display        : flex;
        align-items    : flex-start;
        justify-content: space-between;
       
        &.p-t-b {
            padding: 16px 0;
        }
    
        .cart-summary__title {
            line-height: 30px;
            color      : $pc-gray-900;
            font-family: NotoSemiBold;
        }
    
        .cart-summary__subtitle-desc {
            line-height : 24px;
            color       : $pc-gray-900;
            font-family : NotoMedium;
            white-space : nowrap;
            margin-right: 15px;
        }
    
        .cart-summary__subtitle {
            line-height : 24px;
            color       : $pc-gray-900;
        }
    
        .cart-summary__total-desc {
            color       : $pc-gray-900;
            font-family : NotoSemiBold;
            line-height : 32px;
            font-size   : 24px;
        }
    }
}

.pay__link {
    width          : 100%;
    justify-content: center;
    margin         : 70px 0 12px;
}

.cart-summary-lock {
    margin-right: 4px;
}

@media (max-width: 1280px) {
    .cart__summary {
        margin-top: 80px;
        width: 100%;

        .cart-summary__item {
            .tooltip__children {
                display: none;
            }
        }
    }
}

@media (max-width: 768px) {
  .cart__summary-project {
    margin-top: 64px;
    padding   : 16px;
  }
}

@media (max-width: 390px) {
    .cart-summary__total_descr {
      display: block;
    }
  }