@import "../../../app/styles/variables";

.profile__purchases {
    background-color: $pc-gray-50;
    margin-top      : 12px;
    padding         : 48px 32px 96px !important;
    align-self      : center;
    width           : 100%;

    .purchases__header {
        width        : 100%;
        padding      : 0 !important;
        margin-bottom: 24px;
    }
    

    .purchases__content {
        display        : flex;
        justify-content: center;
    }

    .admin__table {
        width: 100%;
        border-radius: 8px;
        border       : 1px solid $pc-gray-200;
        background   : $white;
        box-shadow   : 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

        .admin__table-title {
            padding: 19px 24px;

            .purchases__switcher {
                width           : fit-content;
                display         : flex;
                padding         : 4px;
                align-items     : center;
                gap             : 8px;
                background-color: $pc-gray-50;
                border          : 1px solid $pc-gray-100;
                border-radius   : 8px;
            }

            h4 {
                font-family: NotoMedium;
                line-height: 28px;
                color: $pc-gray-900;
            }
        }
    }

    .admin__table, .admin__table-header, .admin__table-row {
        width: 100%;
    }

    .admin__table-header {
        display   : flex;
        height    : 44px;
        background: $pc-gray-50;
        border-top: 1px solid $pc-gray-200;
        
        .product-name, .project-name, .product-illustrator, 
        .product-license, .product-price, .product-date, .product-download {
            line-height: 20px;
            color      : $pc-gray-500;
            font-family: NotoMedium;
            display    : flex;
            align-items: center;
            padding    : 12px 24px;
        }

        .product-name {
            min-width: 299px;
            width: calc(100% - 563px);
        }

        .project-name {
            min-width: 299px;
            width: calc(100% - 563px);
        }

        .product-illustrator, .product-license {
            min-width: 220px;
        }

        .product-price {
            min-width: 84px;
        }
        
        .product-date {
            min-width: 130px;
        } 

        .product-download {
            min-width: 213px;
        }
    }

    .admin__table-body {
        height  : auto;
        overflow: visible;
    }

    .admin__table-row {
        height: 80px;
        cursor: auto;

        &:hover {
            background-color: transparent !important;
        }
    }

    .product-name, .project-name {
        min-width: 299px;
        width: calc(100% - 563px);
        padding: 6px 24px;

        .product-name__icon__wrapper {
            margin-right: 12px;
            min-width: 100px;
            max-width: 100px;
            height: 64px;
            border-radius: 4px;
            border: 1px solid $pc-gray-200;
            overflow: hidden;

            img {
                width: 100%;
                height: 64px;
                object-fit: cover;
            }
        }

        .product-title {
            overflow: hidden;
            max-height: 50px;

            .tooltip__children {
                display: none;
            }
        }

        h5 {
            line-height  : 24px;
            color        : $pc-gray-700;
            overflow     : hidden;
            /*text-overflow: ellipsis;*/
            /*text-wrap    : nowrap;*/
            -webkit-line-clamp: 2; /* Число отображаемых строк */
            display           : -webkit-box; /* Включаем флексбоксы */
            -webkit-box-orient: vertical; /* Вертикальная ориентация */
        }
    }

    .product-illustrator {
        padding   : 16px 24px;
        min-width : 220px;
        overflow  : hidden;

        .illustrator-avatar__wrapper {
            margin-right: 12px;
        }

        h5 {
            line-height: 24px;
            color      : $pc-gray-500;
            -webkit-line-clamp: 2; /* Число отображаемых строк */
            display           : -webkit-box; /* Включаем флексбоксы */
            -webkit-box-orient: vertical; /* Вертикальная ориентация */
        }
    }

    .product-license {
        min-width  : 220px;
        padding: 16px 24px;

        h6 {
            line-height  : 20px;
            color        : $pc-gray-500;
            font-family  : NotoBold;
        }
    }

    .product-price {
        width    : 84px;
        min-width: 84px;
        padding  : 16px 24px;

        h6 {
            line-height  : 20px;
            color        : $pc-gray-500;
        }

        .badge__container {
            margin-top: 0;
        }
    }

    .product-date {
        width    : 130px;
        min-width: 130px;
        padding  : 16px 24px;

        h6 {
            line-height  : 20px;
            color        : $pc-gray-500;
        }
    }

    .product-download {
        min-width  : 213px;
        padding: 16px 24px;

        button {
            width          : 100%;
            justify-content: center;
        }
    }

    .pagination__container {
        margin  : 0;
        padding : 16px 0;
        position: relative;

        .antd-dropdown__container {
            position: absolute;
            width: 83px;
            right: 22px;
        }
    }
}

@media (max-width: 1028px) {
    .profile__purchases {
        .admin__table-header {
            .product-name {
                min-width: 269px;
                width: calc(100% - 526px);
            }

            .project-name {
                min-width: 269px;
                width: calc(100% - 440px);
            }

            .product-illustrator, 
            .product-license {
                min-width: 228px;
            }

            .product-download {
                display: none;
            }
        }

        .product-name {
            width: calc(100% - 500px);
        }

        .project-name {
            width: calc(100% - 410px);
        }

        .product-illustrator, 
        .product-license {
            min-width: 228px;
        }

        .product-download {
            min-width: 84px;
    
            button {
                padding: 7.5px 8px;

                .button__container-icon {
                    margin-right: 0;
                }
                span {
                    display: none;
                }
            }
        }
    }
}

@media (max-width: 910px) {
    .profile__purchases {
        .admin__table-header {
            .product-name {
                width: calc(100% - 512px);
            }
            .project-name {
                width: calc(100% - 428px);
            }
            .product-illustrator, 
            .product-license {
                min-width: 214px;
            }
        }

        .product-name {
            width: calc(100% - 468px);
        }

        .project-name {
            width: calc(100% - 379px);
        }

        .product-illustrator, 
        .product-license {
            min-width: 214px;
        }
    }
}

@media (max-width: 894px) {
    .profile__purchases {
        padding: 32px 0 !important;

        .purchases__header {
            padding: 0 24px !important;

            .title {
                font-size: 24px !important;
                line-height: 32px !important;
            }
        }

        .admin__table {
            border: none;
            border-radius: 0;

            .admin__table-title {
                padding: 15px 24px;
            }
        }

    }
}

@media (max-width: 828px) {
    .profile__purchases {
        .admin__table-header {
            .product-name, .project-name {
                width: 100%;
            }
            .product-illustrator, 
            .product-license,
            .product-price,
            .product-date {
                display: none;
            }
        }

        .product-name, .project-name {
            min-width: 200px;
            width: calc(100% - 76px);
            align-items: flex-start;
            padding: 8px 24px;

            .product-title {
                max-height    : none;
                height        : 64px;
                display       : flex;
                flex-direction: column;
                position      : relative;
                overflow      : visible;

                h5 {
                    font-size  : 14px;
                    line-height: 20px;
                }

                .tooltip__children {
                    display   : block;
                    margin-top: 4px;
                    position  : absolute;
                    bottom    : 0;

                    .tooltipe__container {
                        width : 256px;
                        height: -moz-fit-content;
                        height: fit-content;
                        bottom: 25px;
                    }

                    .tooltip__ancor {
                        bottom: -5px;
                        right : auto;
                    }

                    .product__tooltip-line {
                        display: flex;
                        justify-content: space-between;

                        .tooltip-name {
                            font-family: NotoMedium;
                            color: $pc-gray-900;
                        }
                    }
                }

                .product-info {
                    font-size  : 14px;
                    font-family: NotoBold;
                    line-height: 20px;
                    color      : $pc-accent-700;
                }
            }
        }

        .product-illustrator, 
        .product-license,
        .product-price,
        .product-date {
            display: none;
        }

        .product-download {
            min-width: 76px;
            padding: 22px 20px;
        }

        .pagination__container {
            .antd-dropdown__container {
                display: none;
            }
        }
    }
}

@media (max-width: 606px) {
    .profile__purchases {
        margin-top: 0;
    }
}

@media (max-width: 400px) {
    .profile__purchases {
        .product-name {
            .product-name__icon__wrapper {
                min-width: 56px;
                max-width: 56px;
            }
        }
    }
}
