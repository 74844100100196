@import "../../app/styles/variables";

.profile__input, .profile__inputs-block {
    display      :block;
    width        : 100%;
    gap          : 24px;

    .input__container, .antd-dropdown__container {
        width: 100%;
        height: 46px;
    }

    .dropdown-label {
        position   : relative;
        top        : -1px;
        font-family: NotoMedium;
        font-size  : 14px;
        color      : $pc-gray-700;
        line-height: 20px;
    }

    .tooltip__children {
        width: auto;
        .help__icon {
            position : absolute;
            right    : 14px;
            top      : -31px;
        }

        .tooltipe__container {
            height: -webkit-fit-content;
            height: -moz-fit-content;
            height: fit-content;
            right: 3px;
            top: -155px;

            .tolltipe__title {
                color: $pc-gray-700;
                font-family: NotoSemiBold;
                font-size: 14px;
                line-height: 20px;
                margin-top: 4px;
            }

            .tolltipe__subtitle {
                color: $pc-gray-500;
                font-size: 14px;
                line-height: 20px;
                margin-top: 4px;
            }
        }
    }
}

.profile__inputs-block {
    display      : flex;
}

.profile__inputs-drop {
    width        : 100%;
    margin-bottom: 20px;
    display      : flex;
    gap          : 24px;

    .antd-dropdown__container, .input__container {
        height: 46px;

        .input-search__list {
            min-width: 160px;
        }

        .input-search__item {
            gap: 8px;
        }
    }

    .input {
        background-color: $white;
    }

    .dropdown-label {
        position   : absolute;
        top        : -23px;
        font-family: NotoMedium;
        font-size  : 14px;
        color      : $pc-gray-700;
        line-height: 20px;
    }
}

.mb-43 {
    margin-bottom: 43px;
}
