@import "../../../app/styles/variables";

.avatar-group {
    display    : flex;
    align-items: center;

    .avatar__wrapper {
        border    : 1.5px solid $white!important;
        box-shadow: none!important;
        width     : 36px;
        height    : 36px;
    }

    .avatar__wrapper:nth-child(n+2){
        margin-left: -9px;
    }

    .button {
        padding   : 0;
        box-shadow: none!important;
    }

    .user-name {
        margin-left: 12px;
        line-height: 24px;
        color      : $pc-gray-500;
    }

    .avatar-add {
        display         : flex;
        justify-content : center;
        align-items     : center;
        border-radius   : 50%;
        margin-left     : 8px;
        border          : 1px dashed $pc-gray-300;
        background      : rgba(255, 255, 255, 0.60);
        backdrop-filter : blur(4px);
        cursor          : pointer;

        img {
            width: 16px;
        }

        &:hover {
            background: $pc-gray-50;
        }

        &:active {
            background: $white;
            box-shadow: 0px 0px 0px 4px $pc-gray-400;
        }

        &.disabled {
            pointer-events: none;
            border        : 1px dashed $pc-gray-200;
            background    : $pc-gray-50;

            img {
                opacity: 0.6;
            }
        }

    }

    /** SIZE **/
    &.xs {
        .avatar-add {
            width: 28px;
            height: 28px;
        }
    }

    &.sm {
        .avatar-add {
            width: 36px;
            height: 36px;
        }
    }

    &.md {
        .avatar-add {
            width: 44px;
            height: 44px;
        }
    }
}