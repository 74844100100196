@import "../../../app/styles/variables";

.steps-toggle__container {
    margin-left   : 16px;
    padding-bottom: 14px;
    margin-bottom : 26px;

    .tooltip__children {
        &.view {
            .tooltipe__container {
                display: block;
            }
        }
    }

    .tooltipe__container {
        width: 244px;
        left : -1px;

        .button {
            padding   : 0;
            margin-top: 4px;
        }
    }
}

.step-toggle-duration_container {
    width   : 44px;
    position: relative;
    left    : 16px;

    .step-duration {
        height    : 22px;
        width     : 4px;
        background: $pc-gray-100;

        &.passed {
            background   : $pc-accent-600;
            border-radius: 0 0 2px 2px;
            height       : 22px;
            z-index      : 2;
        }
    }
}

.step-toggle-milestone__container {
    position: relative;
    width   : 231px;

    .step-milestone__description {
        position: absolute;
        top     : -5px;
        left    : 44px;
    }

    .step-milestone-datepicker__icon {
        position : absolute;
        right    : 10px;
        top      : 50%;
        transform: translateY(-50%);
        cursor   : pointer;
        padding  : 10px;
   

        :active {
            border-radius: 50%;
            box-shadow   : 0px 0px 0px 4px #F2F4F7, 0px 1px 2px 0px rgba(16, 24, 40, 0.05);   
        }
    }

    .step-milestone__title {
        color      : $pc-gray-300;
        font-family: NotoSemiBold;
        line-height: 20px;
        white-space: nowrap;
        margin-left: 12px;
        

        &.passet {
            color: $pc-accent-700;
        }
    }

    .step-milestone__suptitle {
        line-height: 20px;
        color      : $pc-gray-300;
        white-space: nowrap;
        margin-left: 12px;

        &.passet {
            color: $pc-gray-500;
        }
    }

    .step-switcher {
        position: relative;
        
        &.notextrimе {
            margin-top: 20px;

            &::after {
                content   : "";
                display   : block;
                width     : 4px;
                background: $pc-gray-100;
                position  : absolute;
                bottom    : 100%;
                height    : 22px;
                left      : 16px;
            }
        }

        &.passed {
            &::after {
                background: $pc-accent-600;
            }
        }

        img {
            width : 14px;
            height: 14px;
        }
    }
}
