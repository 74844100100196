@import "../../../app/styles/variables";

.chats__panel {
    outline : 1px solid $pc-gray-50;
    width   : 100%;
    position: relative;

    .background-default {
        align-items: center;
        overflow   : auto;
        padding    : 16px;
    }

    .chats__header {
        height         : 52px;
        padding        : 12px 32px;
        align-items    : center;
        justify-content: space-between;
        border-bottom  : 1px solid $pc-gray-200;

        &.deleted {
            background: $pc-error-100;

            .admin-menu__item {
                background: $pc-error-100;
            }
        }

        &.chats__header-user {
            height : 57px;
            padding: 0 0 0 16px;
        }

        .chats__header-title,
        .chats__tablet-header-title,
        .chats__mobile-header-title {
            display: none;
        }

        .user-info-tablet__wrapper, 
        .admin-info-tablet__wrapper {
            .background-default {
                top            : 57px;
                position       : absolute;
                overflow       : auto;
                backdrop-filter: none;
                background     : rgba(52, 64, 84, 0.30);
                z-index        : 99;

                &::after {
                    content: "";
                    -webkit-backdrop-filter: blur(4px);
                    backdrop-filter: blur(4px);
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                }
            }

            .panel-info__wrapper, .admin-info__wrapper {
                position: absolute;
                z-index : 9;
                background-color: white;
                width   : 312px;
                top     : 0;
                right   : 0;
                bottom  : 0;
                overflow: auto;
                padding : 0 32px;
            }

            .user__info-menu {
                min-width: 295px;
            }
        }

        .admin-info-tablet__wrapper {
            .background-default {
                top: 52px;
            }

            .admin-info__wrapper {
                padding-top: 16px;
                height     : calc(100dvh - 193px);
            }
        }
    }

    .chat__body {
        background: $pc-gray-100;
        flex: 1;
        position: relative;

        .chat__body-main {
            display       : flex;
            position      : absolute;
            flex-direction: column-reverse;
            width         : 100%;
            height        : 100%;
            overflow-y    : auto;
            padding       : 10px 32px 8px;

            &::-webkit-scrollbar {
                display: none;
            }

            &.notification__padding {
                padding-top: 78px;
            }

            
        }
    }

    .chat__empty {
        background     : $white;
        display        : flex;
        position       : absolute;
        justify-content: center;
        align-items    : flex-start;
        padding        : 88px 32px;
        border-right   : 1px solid $pc-gray-200;
        border-bottom  : 1px solid $pc-gray-200;
        bottom         : 0;
        top            : 0;
        left           : 0;
        right          : 0;
        width: 100%;
        height: 100%;

        .empty-label {
            width: 216px;
            display: flex;
            padding: 4px 12px;
            justify-content: center;
            align-items: center;
            font-family: NotoMedium;
            color: $pc-gray-700;
            line-height: 20px;
            background-color: $pc-gray-100;
            border-radius: 16px;
        }
    }

    &.user-pannel-chat {
        width: 100%;

        .chats__header-title {
            display    : inline-block;
            line-height: 28px;
            font-family: NotoSemiBold;
            color      : $pc-gray-900;
            text-overflow: ellipsis;
            overflow   : hidden;
            white-space: nowrap;
        }

        .chat__body-main {

            &::-webkit-scrollbar {
                display: none;
            }
        }

        .admin__info {
            display: none;
        }

        .greetings-message {
            max-width: 592px;
            padding: 32px;
            margin-bottom: 10px;
            background: $white;
            box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);

            .greetings-message__conteiner {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 32px;
            }

            .greetings-message__content {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .sub-title {
                    color: $pc-accent-700;
                    font-family: NotoBold;
                    font-size: 16px;
                    line-height: 24px;
                    margin-bottom: 12px;
                }
                .title {
                    color: $pc-gray-900;
                    font-family: NotoBold;
                    font-size: 30px;
                    line-height: 38px;
                    margin-bottom: 32px;
                }
                .check-items {
                    display: flex;
                    padding-left: 16px;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 16px;
                    align-self: stretch;

                    .check-item {
                        display: flex;
                        align-items: self-start;

                        img {
                            margin: 5px;
                        }

                        h5 {
                            line-height: 24px;
                            margin-left: 12px;
                        }
                    }
                }
            }

            .greetings-message__image {
                max-width: 468px;
                img {
                    width: 100%;
                }
            }
        }
    }

    .file-messages__container {
        background    : $white;
        display       : flex;
        flex-wrap     : wrap;
        padding       : 16px;
        padding-bottom: 0px;
    }
}

@media (min-width: 1280px) {
    .chats__panel {
        .chats__header {
            .admin-info-tablet__wrapper {
                display: none;
            }
        }
    }
}

@media (min-width: 1029px) {
    .chats__panel {
        .chats__header {
            .user-info-tablet__wrapper {
                display: none;
            }
        }
    }
}

@media (max-width: 862px) {
    .chats__panel {
        .chats__header {
            &.chats__header-user {
                align-items    : flex-end;
                flex-direction : column;
                height         : 113px;
                padding        : 0;
                border-bottom  : none;
            }

            .user__info-menu {
                border-bottom: none;
            }

            .notification-project__wrapper {
                top: 113px;
            }
        }

        &.user-pannel-chat {
            .chats__header-title {
                display: none;
            }

            .chats__tablet-header-title {
                display    : block;
                height     : 56px;
                padding    : 8px 32px;
                line-height: 28px;
                font-family: NotoSemiBold;
                color      : $pc-gray-900;
                align-content   : center;
                background-color: $white;
                border-top      : 1px solid $pc-gray-200;
                border-bottom   : 1px solid $pc-gray-200;
                text-overflow   : ellipsis;
                overflow   : hidden;
                white-space: nowrap;
                width      : 100%;
            }

            .chat__body {
                .chat__body-main {
                    &.notification__padding {
                        padding-top: 122px;
                    }
                }
            }
        }
    }
}

@media (max-width: 670px) {
    .chats__panel {
        .chats__header {
            flex-direction: column;
            align-items   : initial;
            justify-content: center;

            &.chats__header-user {
                border-bottom: 1px solid $pc-gray-200;
            }

            .chats__tablet-header-title {
                display: none;
            }

            .chats__mobile-header-title {
                display      : flex;
                width        : 100%;
                padding      : 8px 16px;
                gap          : 16px;
                height       : 56px;
                border-bottom: 1px solid $pc-gray-200;
                align-items  : center;
                justify-content: space-between;

                .chats__mobile-header-info {
                    display      : flex;
                    align-items  : center;
                    gap          : 16px;
                }

                .button {
                    padding: 0;

                    .button__container-icon {
                        margin-right: 0;
                        width: 32px;
                        height: 32px;
                    }
                }

                .user-project__wrapper {
                    width    : 40px;
                    min-width: 40px;
                    max-width: 40px;
                    height   : 40px;

                    .user-project__preview-img {
                        height         : 40px;
                        object-fit     : cover;
                        width          : 100%;
                        object-position: center;
                        border-radius  : 50%;
                    }
                }

                h6 {
                    color      : $pc-gray-900;
                    font-family: NotoMedium;
                    font-size  : 14px;
                    line-height: 20px;

                    display           : block;
                    display           : -webkit-box;
                    overflow          : hidden;
                    white-space       : wrap;
                    overflow-wrap     : break-word;
                    line-clamp        : 2;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
            }

            .user-info-tablet__wrapper {
                width          : 100%;
                display        : flex;
                justify-content: flex-end;
                overflow: hidden;

                .background-default {
                    height: calc(100dvh - 113px);
                    top: 113px;
                }
            }

            .user__info-menu {
                padding: 16px;
            }
        }

        &.user-pannel-chat {
            .chat__body {
                .chat__body-main {
                    margin-bottom: 0;
                }

                .notification-project__wrapper {
                    top: 0;
                }
            }
        }
    }
}
