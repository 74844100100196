@import "../../app/styles/variables";

.tags__container {
    margin: 25px 24px 0;

    .slick-slider {
        margin-bottom: 0;
    }

    .slick-list {
        overflow: hidden !important;
    }

    .carousel-arrow {
        display        : flex !important;
        position       : absolute;
        align-items    : center;
        justify-content: center;

        &::before {
            content: "";
        }
    }

    .products-tags__item {
        color      : $pc-gray-500;
        font-family: NotoSemiBold;
        margin-left: 1px;
        height     : 36px;
    }
}

.tags-arrow{
    position : absolute;
    right    : 0;
    top      : 50%;
    transform: translateY(-50%);
    z-index  : 3;
}