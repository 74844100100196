@import "../../app/styles/variables";

.snack__wrapper {
    padding        : 16px;
    display        : flex;
    justify-content: space-between;
    width          : 665px;
    position       : absolute;
    right          : 0;
    border-radius  : 8px;
    border         : 1px solid $pc-gray-300;
    box-shadow     : 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    background     : $white;

    .snack__header{
        font-size    : 14px;
        line-height  : 20px;
        font-family  : NotoSemiBold;
        margin-bottom: 4px;
    }

    .snack__content {
        display    : flex;
        align-items: center;
    }

    .snack__text {
        font-size  : 14px;
        line-height: 20px;
    }

    .snack__close {
        margin-left: 16px;
        cursor     : pointer;
    }

    .snack__actions {
        margin-top: 12px;

        .button {
            padding: 0;
        }
    }

    .snack__status {
        background-repeat  : no-repeat;
        width              : 40px;
        height             : 40px;
        min-width          : 40px;
        background-position: center;
        border-radius      : 50%;
        margin-right       : 19px;
    }

    &.status-default {
        .snack__status {
            background-image: url('../../shared/ui-kit/icons/snack/default.svg');
        }
    }

    &.status-100 {
        .snack__status {
            background-image: url('../../shared/ui-kit/icons/snack/100.svg');
        }
    }

    &.status-300 {
        .snack__status {
            background-image: url('../../shared/ui-kit/icons/snack/300.svg');
        }
    }

    &.status-500 {
        .snack__status {
            background-image: url('../../shared/ui-kit/icons/snack/500.svg');
        }
    }

    &.status-400 {
        .snack__status {
            background-image: url('../../shared/ui-kit/icons/snack/400.svg');
        }
    }

    &.status-200 {
       .snack__status {
            background-image: url('../../shared/ui-kit/icons/snack/200.svg');
        }
    }

    &.status-205 {
        .snack__status {
             background-image: url('../../shared/ui-kit/icons/snack/205.svg');
         }
    }

    &.status-250 {
        .snack__status {
             background-image: url('../../shared/ui-kit/icons/snack/250.svg');
         }
    }
}

@media (max-width: 706px) {
    #snackbar-portal {
        left: 22px;
    }
    .snack__wrapper {
        width: auto;
    }
}
