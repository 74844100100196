@import "../../../../app/styles/variables";

.notification-popup__wrapper {
    border-radius: 12px;
    background   : $white;
    padding      : 20px 16px;
    width        : 343px;
    z-index      : 9;

    .notification-popup__title {
        line-height  : 28px;
        font-family  : NotoSemiBold;
        color        : $pc-gray-900;
        margin-bottom: 8px;
    }

    .notification-popup__subtitle {
        line-height  : 20px;
        color        : $pc-gray-500;
        margin-bottom: 16px;
    }

    .notification-popup__list {
        .notification-popup__list-title {
            line-height  : 20px;
            font-family  : NotoMedium;
            margin-bottom: 6px;
            color        : $pc-gray-700;
        }
        .antd-dropdown__container {
            width: 100%;

            .input__wrapper.active {
                .input-search__list {
                    position: sticky;
                    left: 23% !important;
                    top: 46% !important;
                }
            }
        }
    }

    .notification-hours-minutes {
        display        : flex;
        justify-content: space-between;
        margin-top     : 32px;

        .input__container {
            width: 147px;
        }
    }

    .notification-popup__invoice {
        margin-top: 32px;

        .check__container {
            margin-top  : 6px;
            padding-left: 24px;
            height: 20px;
            align-items: center;
        }
    }

    .notification__btn {
        width          : 100%;
        justify-content: center;
        margin-top     : 23px;

        &.publish{
            margin-bottom: 12px;
        }
    }

    .error {
        margin-top: 5px;
        display   : block;
    }
}