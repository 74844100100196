@import "../../../../app/styles/variables";

.activated-email__bkg {
    overflow: auto;
    z-index: 99;
}

.activated-email-modal {
    border-radius: 12px;
    background   : $white;
    padding      : 24px;
    width        : 452px;
    z-index      : 9;
    margin       : 16px;

    &.notification-popup__wrapper {
        top          : -21px;
        position     : relative;
    }

    .activated-email-modal__content {
        display       : flex;
        flex-direction: column;
        align-items   : center;
        gap           : 20px;
        margin-bottom : 43px;

        .title-image {
            display    : flex;
            align-items: center;
            position   : relative;
            width      : 134px;
            height     : 101px;

            .icon-featured__container {
                position : absolute;
                left     : -18px;
                bottom   : 0;
                min-width: 48px;

                img {
                    width: 24px;
                }
            }

            .icon-ava__img {
                position: absolute;
                width   : 116px;
            }
        }

        .title {
            line-height  : 28px;
            font-family  : NotoSemiBold;
            color        : $pc-gray-900;
            margin-bottom: 8px;
        }

        .subtitle {
            line-height: 20px;
            color      : $pc-gray-500;
        }
    }

    .activated-email-modal__actions {
        margin-top: 24px;

        .activated-email-modal__btn {
            width          : 100%;
            display        : flex;
            justify-content: center;
            margin-top     : 32px;
        }
    }
}

@media (max-height: 600px) {
    .activated-email__bkg {
        align-items: baseline;
    }

    .activated-email-modal {
        &.notification-popup__wrapper {
            top: auto;
        }
    }
}