@import "../../../app/styles/variables";

.admin-info__wrapper {
    min-width  : 312px;
    max-width  : 312px;
    padding    : 32px 32px 0 32px;

    &.chat {
        display: none;
    }
}
