@import '../../../app/styles/variables';

.user-project__img-wrapper {
    display         : flex;
    position        : relative;
    width           : 68px;
    min-width       : 68px;
    height          : 68px;
    align-items     : center;
    border-radius   : 4px;
    border          : 1px solid $pc-gray-200;
    background-color: $pc-accent-100;
    overflow        :hidden;
    cursor          : pointer;
    justify-content : center;
    align-items     : center;

    input {
        display: none;
    }

    .user-project__preview-img {
        width        : auto;
        height       : 100%;
        border-radius: 4px;
    }

    .button__image-add {
        display         : flex;
        width           : 24px;
        height          : 24px;
        justify-content : center;
        align-items     : center;
        position        : absolute;
        left            : 22px;
        top             : 22px;
        border-radius   : 12px;
        border          : 1px dashed $pc-gray-300;
        background      : rgba(255, 255, 255, 0.60);
        backdrop-filter : blur(4px);

        .button__placeholder {
            width  : 24px;
            padding: 4px;
        }
    }
}