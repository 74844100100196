@import "../../../app/styles/variables";

.carousel-arrow {
    border-radius   : 50%;
    cursor          : pointer;
    display         : flex;
    justify-content : center;
    align-items     : center;
    transition      : background-color 0.2s ease-in-out;
    z-index         : 9;

    /* Disable element selection */
    user-select        : none; 
    -webkit-user-select: none;
    -moz-user-select   : none;
    -ms-user-select    : none;

    img {
        opacity: 0.745;
    }

    &.next {
        img {
            transform: rotate(180deg);
        }
    }

    &:hover {
        img {
            opacity: 1;
        }
    }

    &.disabled {
        cursor: default;
        img {
            pointer-events: none; 
            opacity       : 0.23;
        }
    }

    /* SIZE */
    &.sm {
        width : 36px;
        height: 36px;
    }

    &.md {
        width : 48px;
        height: 48px;
        &.arrow {
            img {
                width : 40px;
                height: 20px;
            }
        }
    }

    &.lg {
        width : 56px;
        height: 56px;
        img {
            width : 24px;
            height: 24px;
        }
        &.arrow {
            img {
                width : 48px;
            }
        }
    }

    /* THEME */
    &.button-chevron, .button-arrow {
        background: $white;
        border    : 1px solid $pc-gray-200;
    
        &:hover {
            background-color: $pc-gray-50;
        }

        &.disabled {
            border: 1px solid $pc-gray-100;
        }
    }

    &.arrow {
        background: transparent;
    }
}


