@import "../../app/styles/variables";

ul.header__navigation {
 display         : flex;
 align-items     : center;
 list-style-type : none;

    li:first-child{
        margin-right: 8px;
    }

    li:not(:first-child){
        padding-left: 32px;
    }
    
    li {
        a {
            font-family: NotoSemiBold;
            white-space: nowrap;
            &:hover {
                text-decoration: none!important;
            }
        }

        .header__logo-icon {
            display: block;
            width : 138px;
            height: 32px;
            cursor: pointer;
            
            img {
                width : 100%;
                height: 100%;
            }
        }
    }
}


@media (max-width: 1065px) {
    ul.header__navigation {
        display: none;
    }
}


@media (max-width: 606px) {
    .header__form-container {
        display: none;
    }
}

