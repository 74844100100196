@import "../../../app/styles/variables";

.short-info {
    display    : flex;
    align-items: center;
    width      : 100%;
    gap        : 8px;

    .short-info__wrapper {
        gap        : 8px;
        display    : flex;
        align-items: center;
    }

    .short-item__content {
        .icon__shape {
            cursor: pointer;
        }

        .notification-project__wrapper {
            top: 52px;
            display: none;

            &.view {
                display: block;
            }

            .notification-project__descr {
                flex-direction: row;
            }
            
            .remove__btn {  
                position: static;
            }
        }
        
        .notification__show-less {
            display: none;
        }
    }

    .short-info__stage {
        display: flex;
        padding: 4px 12px;
        justify-content: center;
        align-items: center;
        border-radius: 16px;
        background: $pc-gray-100;
        font-size : 14px;
        font-family: NotoMedium;
        line-height: 20px;

        &.disabled {
            display: none;
        }
    }

    .short-info__paid {
        display: flex;
        padding: 4px 12px;
        justify-content: center;
        align-items: center;
        border-radius: 16px;
        font-family: NotoMedium;
        line-height: 20px;

        img {
            width: 12px;
            height: 12px;
            margin-right: 4px;
        }

        &.paid {
            color     : $pc-success-700;
            background: $pc-success-100;
        }

        &.unpaid {
            color     : $pc-warning-700;
            background: $pc-warning-100;
        }
    }
}

@media (max-width: 670px) {
    .short-info {    
        .short-item__content {
            .notification-project__wrapper {
                top: 108px;
            }
        }
    }
}
