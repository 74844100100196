@import "../../app/styles/variables";

.hoverbackground__container {
  position       : absolute;
  bottom         : 0;
  top            : 0;
  left           : 0;
  right          : 0;
  height         : 100%;
  padding        : 16px;
  display        : flex;
  color     : $pc-gray-100;

  &:hover {
    background: linear-gradient(180deg, rgba(57, 75, 126, 0.12) 0.06%, rgba(1, 13, 45, 0.55) 85.46%);
  }

  .hoverbackground__info {
    .link-gray {
      color: $pc-gray-100;
      padding: 0;
      &:hover {
      text-decoration: underline;
      }
    }
  }
  &.resizer {
    .hoverbackground__info {
      .avatar-label__container {
        margin-bottom: 0;
        .avatar__wrapper {
          display: none;
        }
      }
    }
  }

  &.cut-license {
    .hoverbackground__info {
      .tooltip__children {
        display: none;
      }
    }
  }

  &.cut-text {
    justify-content: flex-end;

    .parent__info {
      width: auto;
    }

    .hoverbackground__info {
      display: none;
    }
  }

  &.show {
    display: flex;
  }

  .parent__info {
    width: 100%;
    display: none;
  }

  .cart__ico-text {
    margin-left: 2px;
  }

  h6 {
    font-family: NotoSemiBold;
    line-height: 20px;
  }

  .avatar__wrapper {
    min-width   : 32px;
    margin-right: 10px;
  }

  .license {
    text-align : left;
    font-family: NotoMedium;
  }

  .cart__button {
    .button__container-icon {
      margin-right: 0;
    }
    .cart__ico-text {
      font-family: NotoSemiBold;
      font-size  : 16px;
      line-height: 24px;
      margin-left: 8px;
      display    : none;
    }

    &.cart__icon {
      padding   : 12px 12px;
      background: $white;
      border    : 1px solid $pc-gray-300;
    }

    &.secondary {      
      .cart__ico-text {
        display: block;
      }
    }
  }

  .hoverbackground__tooltip {
    color        : $pc-gray-700;
    margin-bottom: 4px;
    font-family  : NotoSemiBold;
  }
  
  .hoverbackground__learn-more{
    color      : $pc-gray-500;
    font-family: NotoRegular;
  }
}
