@import "../../../../app/styles/variables";

.product-edit__container {
    .product-edit__title-actions {
        margin-bottom: 24px;
    }

    .product-edit__info-container {
        padding: 24px;
        border-radius: 12px;
        border: 1px solid $pc-gray-200;

        .edit__info-title {
            align-items: center;
            margin-bottom: 24px;

            h4 {
                font-family: NotoBold;
                color: $pc-gray-900;
                line-height: 28px;
            }

            .info-title__actions {
                gap: 24px;

                .button {
                    padding: 0;
                }

                .button__container-icon {
                    margin: 0;
                }
            }
        }

        .edit__image-container {
            width: 520px;
            margin-right: 24px;

            img {
                border-radius: 12px;
                max-width: 520px;
                max-height: 520px;
            }
        }

        .edit__form {
            width: 436px;
            padding-right: 32px;

            textarea {
                height: 142px;
            }

            .info__compatible {
                margin-top: 24px;
            }
        }
    }
}