@import "variables";
@import "App.scss";
@import "reset.scss";

@import 'slick-carousel/slick/slick.css';
@import 'slick-carousel/slick/slick-theme.css';

/********************** FONTS ************************/
@font-face {
   font-family : NotoThin; //100
   src         : url("../../shared/ui-kit/fonts/NotoSans-Thin.ttf");
 }

 @font-face {
   font-family : NotoLight; //300
   src         : url("../../shared/ui-kit/fonts/NotoSans-Light.ttf");
 }

 @font-face {
   font-family : NotoRegular; //400
   src         : url("../../shared/ui-kit/fonts/NotoSans-Regular.ttf");
 }

 @font-face {
   font-family : NotoMedium; //500
   src         : url("../../shared/ui-kit/fonts/NotoSans-Medium.ttf");
 }

 @font-face {
   font-family : NotoSemiBold; //600
   src         : url("../../shared/ui-kit/fonts/NotoSans-SemiBold.ttf");
 }

 @font-face {
   font-family : NotoBold; //700
   src         : url("../../shared/ui-kit/fonts/NotoSans-Bold.ttf");
 }

 @font-face {
   font-family : NotoExtraBold; //800
   src         : url("../../shared/ui-kit/fonts/NotoSans-ExtraBold.ttf");
 }


/********************** SHARED ************************/
* {
   box-sizing  : border-box;
   font-family : NotoRegular;
   font-weight : 400;
}

h1 {
   font-size: 36px;
}

h2 {
   font-size: 28px;
}

h3 {
   font-size: 20px;
}

h4 {
   font-size  : 18px;
   line-height: 28px;
}

h5 {
   font-size  : 16px;
   line-height: 24px;
}

h6 {
   font-size  : 14px;
   line-height: 20px;
}

a {
   text-decoration : none;
   display         : inline-block;
   color           : inherit;
   font-family     : NotoSemiBold;
}

ul, li {
   list-style : none ;
}

/********************** PLACEHOLDERS ************************/
::-webkit-input-placeholder {
   /* WebKit, Blink, Edge */
   color: $pc-gray-500;
}
:-moz-placeholder {
   /* Mozilla Firefox 4 to 18 */
   color: $pc-gray-500;
   opacity: 1;
}
::-moz-placeholder {
   /* Mozilla Firefox 19+ */
   color: $pc-gray-500;
   opacity: 1;
}
:-ms-input-placeholder {
   /* Internet Explorer 10-11 */
   color: $pc-gray-500;
}
::-ms-input-placeholder {
   /* Microsoft Edge */
   color: $pc-gray-500;
}

::placeholder {
   /* Most modern browsers support this now. */
   color: $pc-gray-500;
}

/****************** INPUT[number] *********************/
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  opacity: 0;
  display: none;
}

input[type="number"]::-webkit-outer-spin-button, 
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: textfield;
  width             : 25px;
  position          : absolute;
  top               : 0;
  right             : 0;
  height            : 100%;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
   -webkit-appearance: none;
   margin            : 0;
}

input[type="number"] {
   -moz-appearance: textfield;
   appearance     : textfield;
}

 /****************** POSITION*********************/
.pr {
   position: relative;
}

/********************** SHARED ************************/

.d-flex {
   display: flex;
}

.d-flex-inline {
   display: inline-flex;;
}

.d-grid {
   display: grid;
}

.jc-sb {
   justify-content: space-between;
}

.jc-fe {
   justify-content: flex-end;
}

.jc-center {
   justify-content: center;
}

.jc-start {
   justify-content: flex-start;
}

.ai-center {
   align-items: center;
}

.ai-end {
   align-items: flex-end;
}

.ai-bl {
   align-items: baseline;
}

.as-end {
   align-self: flex-end;
}

.fd-column {
   flex-direction: column;
}

.fw-wrap {
   flex-wrap: wrap;
}

.fw-nowrap {
   flex-wrap: nowrap;
}

.w-100 {
   width: 100%;
}

.error {
   color: $pc-error-500;
}

.error-color {
   color: $pc-error-500 !important;
}

/********************** MARGIN ************************/
.mt-4 {
   margin-top: 4px;
}

.mt-5 {
   margin-top: 6px;
}

.mt-6 {
   margin-top: 6px;
}

.mt-7 {
   margin-top: 7px;
}

.mt-8 {
   margin-top: 8px;
}

.mt-9 {
   margin-top: 9px;
}

.mt-10 {
   margin-top: 10px;
}

.mt-11 {
   margin-top: 11px;
}

.mt-12 {
   margin-top: 12px;
}

.mt-13 {
   margin-top: 13px;
}

.mt-14 {
   margin-top: 14px;
}

.mt-15 {
   margin-top: 15px;
}

.mt-16 {
   margin-top: 16px;
}

.mt-17 {
   margin-top: 17px;
}

.mt-18 {
   margin-top: 18px;
}

.mt-19 {
   margin-top: 19px;
}

.mt-20 {
   margin-top: 20px;
}

.mt-21 {
   margin-top: 21px;
}

.mt-22 {
   margin-top: 22px;
}

.mt-23 {
   margin-top: 23px;
}

.mt-24 {
   margin-top: 24px;
}

.mt-25 {
   margin-top: 25px;
}

.mt-26 {
   margin-top: 26px;
}

.mt-30 {
   margin-top: 30px;
}

.mt-31 {
   margin-top: 31px;
}

.mt-32 {
   margin-top: 32px;
}

.mt-50 {
   margin-top: 50px;
}

.mb-7{
   margin-bottom: 7px;
}

.mb-8 {
   margin-bottom: 8px;
}

.mb-9 {
   margin-bottom: 9px;
}

.mb-10 {
   margin-bottom: 10px;
}

.mb-11 {
   margin-bottom: 11px;
}

.mb-12 {
   margin-bottom: 12px;
}

.mb-13 {
   margin-bottom: 13px;
}

.mb-14 {
   margin-bottom: 14px;
}

.mb-15 {
   margin-bottom: 15px;
}

.mb-16 {
   margin-bottom: 16px;
}

.mb-17 {
   margin-bottom: 17px;
}

.mb-18 {
   margin-bottom: 18px;
}

.mb-19 {
   margin-bottom: 19px;
}

.mb-20 {
   margin-bottom: 20px;
}

.mb-21 {
   margin-bottom: 21px;
}

.mb-22 {
   margin-bottom: 22px;
}

.mb-23 {
   margin-bottom: 23px;
}

.mb-24 {
   margin-bottom: 24px;
}

.mb-30 {
   margin-bottom: 30px;
}

.mb-31 {
   margin-bottom: 31px;
}

.mb-32 {
   margin-bottom: 32px;
}

.mb-34 {
   margin-bottom: 34px;
}

.mb-40 {
   margin-bottom: 40px;
}

.mb-48 {
   margin-bottom: 48px;
}

.mb-118 {
   margin-bottom: 118px;
}

.ml-6 {
   margin-left: 6px;
}

.ml-7 {
   margin-left: 7px;
}

.ml-8 {
   margin-left: 8px;
}

.ml-9 {
   margin-left: 9px;
}

.ml-10 {
   margin-left: 10px;
}

.ml-11 {
   margin-left: 11px;
}

.ml-12 {
   margin-left: 12px;
}

.ml-13 {
   margin-left: 13px;
}

.ml-14 {
   margin-left: 14px;
}

.ml-15 {
   margin-left: 15px;
}

.ml-16 {
   margin-left: 16px;
}

.ml-17 {
   margin-left: 17px;
}

.ml-18 {
   margin-left: 18px;
}

.ml-19 {
   margin-left: 19px;
}

.ml-20 {
   margin-left: 20px;
}

.ml-21 {
   margin-left: 21px;
}

.ml-22 {
   margin-left: 22px;
}

.ml-23 {
   margin-left: 23px;
}

.ml-24 {
   margin-left: 24px;
}

.ml-25 {
   margin-left: 25px;
}

.ml-26 {
   margin-left: 26px;
}

.ml-27 {
   margin-left: 27px;  
}

.ml-40 {
   margin-left: 40px;
}

.mr-6 {
   margin-right: 6px;
}

.mr-7 {
   margin-right: 7px;
}

.mr-8 {
   margin-right: 8px;
}

.mr-9 {
   margin-right: 9px;
}

.mr-10 {
   margin-right: 10px;
}

.mr-11 {
   margin-right: 11px;
}

.mr-12 {
   margin-right: 12px;
}

.mr-13 {
   margin-right: 13px;
}

.mr-14 {
   margin-right: 14px;
}

.mr-15 {
   margin-right: 15px;
}

.mr-16 {
   margin-right: 16px;
}

.mr-17 {
   margin-right: 17px;
}

.mr-18 {
   margin-right: 18px;
}

.mr-19 {
   margin-right: 19px;
}

.mr-20 {
   margin-right: 20px;
}

.mr-21 {
   margin-right: 21px;
}

.mr-22 {
   margin-right: 22px;
}

.mr-23 {
   margin-right: 23px;
}

.mr-24 {
   margin-right: 24px;
}

.mr-30 {
   margin-right: 30px;
}

.mr-32 {
   margin-right: 32px;
}

.mr-34 {
   margin-right: 34px;
}

.mr-40 {
   margin-right: 40px;
}

/********************** PADDING ************************/

.pb-16 {
   padding-bottom: 16px;
}
