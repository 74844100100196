@import "../../../../app/styles/variables";

.promoted__container {
    align-self   : center;
    width        : 100%;

    .promoted__header {
        border       : 1px solid $pc-gray-200;
        border-radius: 12px;
        padding      : 24px;
        margin-bottom: 24px;

        .title {
            margin-bottom: 8px;
            font-family  : NotoBold;
            line-height  : 28px;
            color        : $pc-gray-900;
        }

        .subtitle {
            margin-bottom: 16px;
            line-height  : 24px;
            color        : $pc-gray-500;
        }

        &.pr {
            border   : none;
            max-width: 740px;
            padding  : 0;

            .title {
                margin-bottom: 24px;
                font-size    : 30px;
                line-height  : 38px;
            }

            .subtitle {
                max-width: 466px;
            }
        }
    }

    .promoted__empty {
        display        : flex;
        flex-wrap      : wrap;
        justify-content: center;
        gap            : 24px;

        .promoted__header {
            max-width    : 510px;
            margin-bottom: 0;
        }

        .promote-empty__content {
            margin: 32px 0;

            .list-content {
                gap          : 12px;
                margin-bottom: 16px;
                align-items  : flex-start;

                img {
                    padding: 5px;
                }

                h5 {
                    line-height  : 24px;
                }
            }
        }

        .link {
            width: fit-content;
        }
    }

    .promoted__table-body {
        width    : 100%;
        display  : flex;
        flex-wrap: wrap;
        gap      :12px;

        .promoted-ceil {
            width : 196px;
            height: 196px;
            overflow: hidden;
            position: relative;

            .promoted-ceil__icon__wrapper {
                display: inline-block;

                .modal-image {
                    .modal__children {
                        img {
                            width: 100%;
                            object-fit: cover;
                            height: 196px;
                        }
                    }
                }
            }

            .check__container {
                position: absolute;
                top     : 12px;
                right   : 12px;

                .checkmark {
                    background-color: $white;
                }
            }
        }
    }

    .pagination__container {
        padding      : 16px 0;
        margin-bottom: 0;
    }
}