@import "../../../app/styles/variables";

.licenses__container-banner {
    padding: 96px 0;
}

.licenses__banner {
    width: 50%;
    margin-right: 64px;

    .banner_bg {
        height: 512px;
    }

    .banner__perspective {
        position: absolute;
        height: 512px;
        width: 100%;
        background: linear-gradient(120deg, #5d0927 25%, #95386f 80%, #a5104e 96%);
    }
    .banner__clip {
        position: absolute;
        right: 0;
        border-right: 270px solid $white;
        border-bottom: 512px solid transparent;
    }

    .banner__img {
        position: absolute;
        right: 24px;
        bottom: 0;
        width: 444px;
        height: 416px;
    }
}

.licenses__title {
    max-width: 576px;

    h1 {
        font-size: 48px;
        line-height: 60px;
        font-family: NotoSemiBold;
        color: $pc-gray-900;
        margin-bottom: 32px;

        .highlight {
            font-family: NotoSemiBold;
            color: $sc-rose-800;
            margin-right: 10px;
        }
    }
}

.licenses__sup-title {
    margin-left: 16px;

    h4 {
        line-height: 28px;
        color: $pc-gray-500;
        margin-left: 19px;
    }
}

@media (max-width: 1140px) {
    .licenses__container-banner {
        padding: 80px 0;
    }

    .licenses__title {
        max-width: 484px;

        h1 {
            line-height: 38px;
            font-size: 30px;
            margin-bottom: 20px;
        }
    }

    .licenses__sup-title {
        h4 {
            font-size: 16px;
            line-height: 24px;
        }
        & div {
            margin-top: 16px;
        }
    }

    .licenses__banner {
        margin-right: 47px;

        .banner__clip {
            border-right: 117px solid #fff;
            border-bottom: 260px solid transparent;
        }

        .banner__perspective {
            height: 261px;
        }

        .banner_bg {
            height: 261px;
        }

        .banner__img {
            width: 226px;
            height: 212px;
            right: 0;
        }
    }
}

@media (max-width: 780px) {
    .licenses__title {
        max-width: 410px;
        margin-right: 10px;
    }
}

//not 606
@media (max-width: 670px) {
    .licenses__container-banner {
        padding-top: 0;
        padding-bottom: 64px;
        flex-direction: column;
        align-items: flex-start;
    }

    .licenses__banner {
        width: 67%;
        margin-right: 0;
        margin-bottom: 48px;
    }

    .licenses__title {
        padding: 0 24px;
        max-width: 100%;
        margin-right: 0;
    }

    div.rights__main-container {
        padding: 64px 24px;
    }
}

@media (max-width: 360px) {
    .licenses__title {
        padding: 0 16px;
    }
    .licenses__banner {
        width: 85%;
        height: 74vw;

        .banner__img {
            width: 64vw;
            height: auto;
        }

        .banner_bg {
            height: 74vw;
        }

        .banner__perspective {
            height: 74vw;
        }

        .banner__clip {
            border-right: 110px solid #fff;
            border-bottom: 265px solid transparent;
        }
    }
}

@media (max-width: 315px) {
    .licenses__title {
        max-width: 100%;
        word-break: break-all;
    }
}
