@import '../../app/styles/variables';

.info__filestype{
    flex-wrap  : wrap;
    line-height: 24px;
    
    .info__header {
        color      : $pc-gray-900;
        white-space: nowrap;
    }

    .info__content{
        color: $pc-gray-500;
    }
}