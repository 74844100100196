@import "../../app/styles/variables";

.price_line {
    line-height: 24px;

    .info__header {
        color      : $pc-gray-900;
        white-space: nowrap;
    }

    .info__content {
        color: $pc-gray-500;
    }

    .tooltip__children{
        display: flex;
    }

    .hoverbackground__tooltip-title {
        line-height: 20px;
        font-family: NotoSemiBold;
        color      : $pc-gray-900;
    }
}
