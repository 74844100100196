@import "../../../../../../app/styles/variables";

.horizontal-buttons {
    display: flex;
    padding: 3px;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid $pc-gray-100;
    background: $pc-gray-50;
    width: fit-content;

    .unactive {
        background-color: transparent;
        border: none;
    }
}