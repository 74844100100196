@import "../../../app/styles/variables";

/********************** QUESTIONS ************************/

.questions__content {
 padding-top   : 96px;
 padding-bottom: 96px;
 text-align    : center;
 line-height   : 44px;
}

.questions__header {
 margin-bottom : 66px;
 
 h1  {
     color         : $pc-gray-900;
     font-family   : NotoSemiBold;
 }

 h3 {
     line-height: 30px;
 }
}

.questions__item {
 max-width  : 405px;
 width      : 100%;
 text-align : left;
 
 h3 {
     line-height   : 30px;
     color         : $pc-gray-900;
     font-family   : NotoMedium;
 }

 h5 {
     line-height: 24px;
 }
}

.questions__item:not(:first-child) {
    margin-left: 32px;
}

.questions__ico {
 position : relative;
 width    : 20px;
 height   : 20px;

 img {
    width : 100%;
    height: 100%;
 }


 &.questions__ico-play {
     img {
        width : 14px;
        height: 18px;
        left  : 57%;
     }
 }
}

@media (max-width: 1065px) {
    .questions__content{
        padding: 80px 24px;

        .questions__header {
            margin-bottom: 48px;

            h1 {
                font-size: 30px;
                line-height: 38px;
            }

            h3 {
                font-size: 18px;
                line-height: 28px;
            }
        }

        .questions__item {
            max-width: 372px;

            &:not(:first-child) {
                margin-left: 24px;
            }

            h3 {
                font-size: 18px;
                line-height: 28px;
                margin-bottom: 4px;
            }
        }

        .questions__ico {
            width: 24px;
            height: 24px;
            margin-bottom: 16px;
        }
    }
}

@media (max-width: 606px) {
    .questions__content {
        padding: 80px 16px;
    }
    
    .questions__header {
        text-align: left;
        h1 {
            margin-bottom: 16px;
        }
    }

    .questions__content{
        .questions__items {
            flex-direction: column;
            .questions__item {
                margin: 0;
                max-width: 100%;
                margin-bottom: 16px;
            }
        }
    }
}