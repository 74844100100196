@import "../../../../app/styles/variables";

.products__menu {
    min-width   : 164px;
    margin-right: 24px;

    .button {
        display: block;
        min-width: 164px;
    }

    .menu__pages {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 0 18px;

        .link-gray {
            &.active {
                color: $pc-accent-700;

                &:hover {
                    color: $pc-accent-800;
                }
            }
        }
    }
}