@import "../../../app/styles/variables";

/********************** What is profitable ************************/

.profitable__container {
    padding         : 34px 0 32px;
    background-color: $pc-accent-50;
    margin-bottom: 64px;
}

.profitable__avatars-group {
    height       : 64px;
    width        : 64px;
    margin-bottom: 34px;
}

.profitable__avatar {
    position          : absolute;
    bottom            : 0;
    -webkit-box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 1);
    -moz-box-shadow   : 0px 0px 0px 2px rgba(255, 255, 255, 1);
    box-shadow        : 0px 0px 0px 2px rgba(255, 255, 255, 1);

   &:nth-child(1) {
        right: 75%;
    }
    &:nth-child(2) {
        z-index: 2;
    }
    &:nth-child(3) {
        left: 75%;
    }
}

.profitable__info {
    margin-bottom: 32px;

    h3 {
        font-family: NotoMedium;
        color      : $pc-gray-900;
        line-height: 30px;
    }

    h4 {
        line-height  : 28px;
    }
}

@media (max-width: 1065px) {
    .profitable__container {
        padding: 24px;
        margin-bottom: 48px;
    }

    .profitable__avatars-group {
        margin-bottom: 24px;
    }

    .profitable__info {
        margin-bottom: 24px;
        text-align: center;
    }
}

@media (max-width: 475px) {
    .profitable__container {
        padding: 24px 16px;
    }
} 
