@import "../../../app/styles/variables";

.footer-link__container {
    span {
        display      : inline-block;
        width        : 42px;
        font-family  : NotoMedium;
        font-size    : 12px;
        line-height  : 18px;
        border-radius: 16px;
        margin-left  : 8px;
        text-align   : center;
    }
    
    a {
        font-size  : 16px;
        line-height: 24px;
    }

    /* THEME */
    &.dark-primary {
        span {
            color           : $pc-accent-700;
            background-color: $pc-accent-50;
        }

        a {
            color: $pc-accent-200;

            &:hover {
                color: $white;
            }
        }
    }

    &.dark-gray {
        span {
            color           : $pc-gray-700;
            background-color: $pc-gray-100;
        }

        a {
            color: $pc-gray-200;

            &:hover {
                color: $pc-gray-50;
            }
        }
    }
}