@import "../../app/styles/variables";

.sub-footer__container {
    border-top: 1px solid $pc-gray-600;
    padding   : 32px 0 48px;

    .sub-footer__logo {
        margin-right: 16px;
    }
}

.sub-footer__content {
    flex-direction : row;
    width          : 100%;
    justify-content: space-between;
    align-items    : center;
}

.sub-footer__logo {
    width : 138px;
    height: 32px;
}

.sub-footer__text {
    font-family: NotoRegular;
    font-size  : 16px;
    line-height: 24px;
    color      : $pc-gray-400;
}

.sub-footer__social {
    height        : 24px;
    gap           : 24px;
}

@media (max-width: 768px) {
    .sub-footer__container {
        flex-direction: column;
        //padding-top   : 24px;
    }

    .sub-footer__logo {
        margin-bottom: 16px;
    }
}

@media (max-width: 487px) {
    .sub-footer__content {
        flex-direction : column;
        justify-content: normal;
        align-items    : baseline;
        gap            : 24px;
    }
}
