@import "../../../app/styles/variables";

.legal__item {
    width     : 100%;
    margin-top: 50px;

    .input__container {
        width: 100%!important;
    }

    .d-flex {
        .tooltip__children {
            position: absolute;
            right   : 14px;
            top     : 15px;

            .tooltipe__container {
                right : -11px;
                top   : auto;
                height: auto;
            }
        }
    }

    &.pright {
        .input {
            padding-right: 37px;
        }
    }
}
