@import "../../../../app/styles/variables";

.stage-popup__wrapper {
    border-radius: 12px;
    background   : $white;
    padding      : 20px 16px;
    width        : 343px;
    z-index      : 9;
    margin       : 16px;

    .stage-popup__title {
        line-height  : 28px;
        font-family  : NotoSemiBold;
        color        : $pc-gray-900;
        margin-bottom: 8px;
    }

    .stage-popup__subtitle {
        line-height  : 20px;
        color        : $pc-gray-500;
        margin-bottom: 16px;
    }

    .stage-hours-minutes {
        display        : flex;
        justify-content: space-between;
        margin-top     : 32px;
        margin-bottom  : 7px;

        .input__container {
            width: 147px;
        }
    }

    .stage__btn {
        margin-top     : 23px;
        width          : 100%;
        justify-content: center;

        &.publish{
            margin-bottom: 12px;
        }
    }
}