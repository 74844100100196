@import "../../app/styles/variables";

.message__wrapper {
    display      : flex;
    align-items  : flex-end;
    margin-bottom: 16px;
}

.message__ava {
    margin-right: 12px;
}

.message__container {
    border-radius: 16px 16px 16px 0px;
    border       : 1px solid $pc-gray-200;
    background   : $white;
    box-shadow   :
        0px 1px 2px 0px rgba(16, 24, 40, 0.06),
        0px 1px 3px 0px rgba(16, 24, 40, 0.1);
    padding      : 8px 12px 12px 12px;
    width        : 80%;
    max-width    : fit-content;
    
    .message-text__content {
        max-width    : fit-content;
        word-wrap    : break-word;
        overflow-wrap: anywhere;
        color        : $pc-gray-900;
        line-height  : 24px;
        font-size    : 16px;
    }

    &.margin-left {
        margin-left: 52px;
    }

    &.margin-right {
        margin-right: 60px;
    }

    &.message__right {
        border-radius: 16px 16px 0px 16px;
        background   : $pc-accent-100;
        margin-left  : auto;
        box-shadow   :
            0px 1px 2px 0px rgba(16, 24, 40, 0.06),
            0px 1px 3px 0px rgba(16, 24, 40, 0.1);

        & + .avatar__wrapper {
            margin-left: 12px;
        }
    }

    &.message-img__wrapper {
        max-width: fit-content;
        max-width: -moz-fit-content;
        min-width: 222px;
        padding  : 12px;
    }

    .message__addition {
        display         : flex;
        justify-content : flex-end;
    }

    .message__time {
        font-size   : 12px;
        line-height : 18px;
        margin-right: 4px;
        color       : $pc-gray-500;
    }

    img {
        width: 100%;
    }

    .bot__author {
        color        : $sc-orange-600;
        font-family  : NotoMedium;
        font-size    : 14px;
        line-height  : 20px;
        margin-bottom: 4px;
        display      : block;
    }

    .smile__wrapper {
        .smile {
            position: relative;
            top     : 7px;
            width   : 28px;
            height  : 28px;
            left    : 5px;

            &.cute {
                top     : 6px;
                width   : 24px;
                height  : 24px; 
            }
        }
    }

    p {
        line-height: 24px;
        font-size  : 16px;
    }

    a {
        display: initial;
        color  : $pc-accent-700;
        cursor : pointer;

        &:hover {
            text-decoration      : underline;
            text-decoration-color: inherit;
            text-underline-offset: 4px;
        }
    }

    .message__text-owner {
        line-height  : 20px;
        margin-bottom: 4px;
        color        : $sc-blue-gray-600;
        font-family  : NotoMedium;

        &.creator {
            color        : $sc-orange-600;
            font-family  : NotoMedium;
            margin-bottom: 4px;
        }
    }
    

    .message-image__container {
        display  : flex;
        flex-wrap: wrap;
        width    : 100%;

        .message-image__content {
            padding      : 8px 10px 10px;
            //width        : 222px;
            height       : 222px;
            flex-grow    : 3;
            margin-bottom: 20px;
            position     : relative;
            cursor       : pointer;

            .message-image__hover  {
                position       : absolute;
                top            : 0;
                bottom         : 0;
                left           : 0;
                right          : 0;
                background     : linear-gradient(180deg, rgba(57, 75, 126, 0.05) 0.06%, rgba(1, 13, 45, 0.25) 85.46%);
                margin         : 8px 10px 10px;
                display        : flex;
                align-items    : center;
                justify-content: center;
                border-radius  : 16px;
                display        : none;

                .message-image__icon {
                    width          : 40px;
                    height         : 40px;
                    border-radius  : 50%;
                    background     : $white;
                    display        : flex;
                    align-items    : center;
                    justify-content: center;
                    border         : 1px solid $pc-gray-300;

                    .icon-ava__img  {
                        width : 20px;
                        height: 20px;
                    }
                }
            }

            &:hover {
                .message-image__hover {
                    display: flex;
                }
            }

            .message__addition {
                position: absolute;
                right   : 10px;
                bottom  : -10px;
            }
        }

        .message-image__message {
            object-fit   : cover;
            width        : 100%;
            height       : 100%;
            border-radius: 16px;
        }
    }
}

.message-image__background {
    display        : none;
    justify-content: flex-start;
    align-items    : center;
    background     : $pc-gray-700;
    position       : fixed;
    top            : 0;
    bottom         : 0;
    left           : 0;
    right          : 0;
    z-index        : 99;
    padding        : 64px;
    cursor         : default;

    &.open {
        display: flex;
    }

    .message-image__image {
        display        : flex;
        align-items    : center;
        justify-content: center;
        overflow       : hidden;
        width          : 100%;
        height         : 100%;

        .icon-ava__img  {
            min-width: 222px;
        }
    }

    .icon-ava__img  {
        max-width : 100%; 
        max-height: 100%; 
        width     : auto; 
        height    : auto;
        cursor    : pointer;
    }

    .modal__top-panel {
        display        : flex;
        padding        : 32px 32px 24px 32px;
        justify-content: flex-end;
        align-items    : flex-start;
        position       : absolute;
        top            : 0;
        left           : 0;
        width          : 100%;
        height         : 80px;
        background     : linear-gradient($pc-gray-700 2.82%, rgba(52, 64, 84, 0.00) 97.19%);
    }

    .modal__close {
        cursor: pointer;

        .icon-ava__img {
            width : 24px;
            height: 24px;
        }
    }
}

.message-image__dropdown {
    position     : fixed;
    background   : $white;
    width        : 138px; //240px
    box-shadow   : 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
    border-radius: 8px;
    z-index      : 9;
    overflow     : hidden;
    display      : none;
 
    &.show {
        display: block;
    }

    .message-image__dropdown-item {
        padding      : 10px 16px;
        display      : flex;
        align-items  : center;
        cursor       : pointer;

        &:hover {
            background: $pc-gray-50;
        }

        .icon-ava__img  {
            width       : 16px;
            height      : 16px;
            margin-right: 12px;
        }
    }
}

@media (max-width: 1027px) {
    .message__container {

        .message-text__content {
            max-width: 100%;
        }
    }
}

@media (max-width: 670px) {
    .message__container {
        width: auto;
    }
}
