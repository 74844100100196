@import '../../../app/styles/variables';

.licenses__container-questions {
    padding  : 96px 0 32px;
    max-width: 768px;

    .questions__title {
        line-height: 44px;
        color      : $pc-gray-900;
        font-family: NotoSemiBold;
    }

    .questions__suptitle {
        line-height  : 30px;
        color        : $pc-gray-500;
        margin-bottom: 40px;
    }
}

@media (max-width: 1285px) {
    .licenses__container-questions {
        padding: 80px 24px 24px;

        .questions__suptitle {
            font-size: 20px;
            margin-bottom: 24px;
            text-align: center;
        }
    }

    .accordion__container {
        .accordion__container-item {
            padding: 24px 52px 24px 0;
        }

        .accordion__ico {
            img {
                width: 24px;
                height: 24px;
            }
        }
    }
}

@media (max-width: 606px) {
    .licenses__container-questions {
        padding: 64px 0px 24px;
        max-width: 100%;

        .accordion__container-item {
            padding-right: 32px;
        }
    }
}