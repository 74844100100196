@import "../../../app/styles/variables";

.cookie__container {
    border-radius: 8px;
    border    : 1px solid $pc-gray-300;
    background: $pc-gray-25;
    box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
    padding   : 12px 14px;
    width     : 100%;
    max-width : 600px;
    pointer-events: all;

    &.hidden {
        display: none;
    }

    .cookie__content {
        margin-bottom: 12px;
    }

    .cookie__title {
        line-height: 20px;
        color: $pc-gray-900;
        font-family: NotoSemiBold;
        margin-bottom: 8px;
    }

    .cookie_link {
        font-size: 14px;
        line-height: 20px;
        color: $pc-gray-500;
        font-family: NotoRegular;

        &.link {
            text-decoration: underline;
            text-underline-offset: 4px;
            text-decoration-color: inherit;
        }
    }

    .cookie__actions {
        display    : flex;
        align-items: center;
        gap        :12px;

        .cookie-decline, .cookie-allow {
            width          : 100%;
            display        : flex;
            justify-content: center;
        }
    }
}
