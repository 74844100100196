@import "../../../app/styles/variables";

$header-height: 80px;
$small-header-height: 56px;
$admin-tabs-height: 61px;
$mobile-tabs-height: 107px;

.admin-content {
    height: calc(100dvh - $header-height - $admin-tabs-height);
}

.mobile-admin-panel__wrapper {
    display: flex;
    width  : 100%;

    .projects__panel {
        max-width   : none;
        width       : 100%;
        border-right: none;
        overflow    : hidden;
        position    : unset;

        .admin__table-body {
            height : calc(100dvh - 195px);
        }
    }

    .archive__panel {
        width: 100%;

        .projects__panel .admin__table-body {
                height : calc(100dvh - 266px);
            }
    }

    .chats__panel {
        display: none;

        .chats__header {
            .admin__mobile-info-menu {
                display: none;
            }

            .short-info {
                padding: 8px 16px;
                height : 52px;
                justify-content: space-between;
                width: auto;
            }

            .background-default {
                top: 56px;

                .admin-info__wrapper {
                    height   : calc(100dvh - $small-header-height);
                    width    : 100%;
                    max-width: none;

                    .project__info textarea {
                        width: 100%;
                    }
                }
            }
        }
    }

    &.content-open {
        height : 100dvh;

        .projects__panel, .archive__panel {
            display: none;
        }
    
        .chats__panel {
            display: flex;
        }
    }

    .background-default {
        top: 41px;

        &.delete-modal {
            padding: 16px;
            top    : 0;
        }
    }
}

@media (max-width: 670px) {
    .mobile-admin-panel__wrapper {
        .chats__panel {
            .chats__header {
                height : 108px;
                padding: 0;

                .admin__info {
                    display: none;
                }

                .admin__mobile-info-menu {
                    display: block;

                    .admin__info {
                        display: block;
                    }
                }
            }
        }
    }
}

@media (max-width: 606px) {
    .mobile-admin-panel__wrapper {
        .projects__panel {    
            .admin__table-body {
                height : calc(100dvh - 171px);
            }
        }

        .archive__panel {    
            .projects__panel .admin__table-body {
                    height : calc(100dvh - 242px);
                }
        }
    }
}
