@import "../../../app/styles/variables";

.tooltipe__container {
    position     : absolute;
    background   : $white;
    padding      : 12px;
    border       : 1px solid $pc-gray-200;
    border-radius: 8px;
    white-space  : nowrap;
    z-index      : 999;
    margin-bottom: 10px;
    display      : none;
    box-shadow   : 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);

    &.top {
        bottom       : 100%;
        margin       : 0;
        margin-bottom: 10px;

        &.revers {
            right: 0;
        }
    }

    &.bottom {
        top       : 100%;
        margin    : 0;
        margin-top: 10px;

        &.revers {
            right: 0;
        }
    }

    &.right {
        left       : 100%;
        top         : 50%;
        margin      : 0;
        margin-left: 10px;
        transform   : translateY(-50%);
    }

    &.left {
        right       : 100%;
        top         : 50%;
        margin      : 0;
        margin-right: 10px;
        transform   : translateY(-50%);
    }

    &.middle-top{
        right    : 50%;
        bottom   : 100%;
        transform: translateX(50%);
    }

    &.middle-bottom{
        right    : 50%;
        top      : 100%;
        transform: translateX(50%);
    }
}

.tooltip__ancor {
    width        : 12px;
    height       : 12px;
    border-radius: 1px;
    background   : $white;
    position     : absolute;
    transform    : rotate(45deg);
}

.tooltip__children {
    width: fit-content;
    &:hover {
        .tooltipe__container {
            display: block;
        }
    }
}

.anchor-left-bottom {
    .tooltip__ancor {
        bottom: -6px;
        left  : 12px;
    }
}

.anchor-right-bottom {
    .tooltip__ancor {
        bottom: -6px;
        right : 12px;
    }
}

.anchor-right-middle {
    .tooltip__ancor {
        bottom: calc(50% - 6px);
        right : -6px;
    }
}

.anchor-left-middle {
    .tooltip__ancor {
        bottom: calc(50% - 6px);
        left  : -6px;
    }
}

.anchor-bottom-middle {
    .tooltip__ancor {
        bottom: -6px;
        left  : calc(50% - 6px);
    }
}

.anchor-top-middle {
    .tooltip__ancor {
        top : -6px;
        left: calc(50% - 6px);
    }
}

.anchor-left-top {
    .tooltip__ancor {
        top : -6px;
        left: 12px;
    }
}

.anchor-right-top {
    .tooltip__ancor {
        top  : -6px;
        right: 12px;
    }
}

.question__icon {
    cursor: pointer;
}

